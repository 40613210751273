import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import swal from "sweetalert";
import styles from "../ChatboxNew/ChatboxNew.module.css";
import Avatar from "@mui/material/Avatar";
import { message } from "antd";

const Chat = ({
  SelectedPretrainedModel,
  BotId,
  BotName,
  userName,
  chat,
  setChat,
}) => {
  const [defaultMessages, setDefaultMessages] = useState(null);
  const [disableTextInput, setDisableTextInput] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [que, setQue] = useState("");
  const [newSession, setNewSession] = useState(true);
  const messagesEndRef = useRef(null);

  const AskQuestion = async () => {
    const question = document.getElementById("question").value;
    if (question.trim().length === 0 || BotId === 1) {
      message.error("Input or bot selection cannot be null!");
    } else {
      setChatLoading(true);
      setQue("");
      setChat([...chat, { user: question }]);
      if (question) {
        const body = {
          BotId: BotId,
          Question: question,
          new_session: newSession,
        };
        console.log(body);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_ROOT}/api/bot/query/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              body: JSON.stringify(body),
              cache: "default",
            }
          );

          const data = await response.json();
          console.log("data", data);
          if (chat.length === 0) {
            var chatData = [{ user: question }];
          } else {
            var chatData = [...chat, { user: question }];
          }
          const last = chatData[chatData.length - 1];
          last.bot = data.message;
          chatData[chatData.length - 1] = last;
          setChat(chatData);
          setChatLoading(false);
          document.getElementById("question").value = "";
        } catch (error) {
          setChatLoading(false);
        }
        setNewSession(false);
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   if (BotId !== 1) {
  //     fetch(
  //       `${process.env.REACT_APP_API_ROOT}/api/data/defaultresponses/?BotId=` + BotId,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //         },
  //         cache: "default",
  //       }
  //     )
  //       .then(function (response) {
  //         console.log("resp :", response);
  //         if (response.status === 400) {
  //           console.log("resp :", response);
  //           setDefaultMessages(null);
  //           return null;
  //         }
  //         return response.json();
  //       })
  //       .then(function (data) {
  //         console.log("data : :", data);
  //         if (data) {
  //           console.log("data = :", data);
  //           setDefaultMessages(data.message[0]);
  //         }
  //       });
  //   }
  //   scrollToBottom();
  // }, [BotId, SelectedPretrainedModel, chat]);

  return (
    // <Container className={styles.chatbox}>
    //   <Grid container>
    //     <Grid item className={styles.headerContainer}>
    //       <AccountCircleIcon className={styles.accountCircleIcon} />
    //       <Typography variant="h6" className="header-message">
    //         Chat with {BotName}
    //       </Typography>
    //       <RefreshIcon
    //         onClick={() => setChat([])}
    //         sx={{ ml: "auto", mr: "20px" }}
    //       />
    //     </Grid>
    //     <Grid container component={Paper} className={styles.chatSection}>
    //       <Grid item>
    //         <List className={styles.messageArea}>
    //           <ListItem key="1" sx={{ padding: 1 }}>
    //             <Grid container>
    //               <Grid item xs={12}>
    //                 <Container className={styles.chatAvatareContainer}>
    //                   <AccountCircleIcon
    //                     sx={{ float: "left" }}
    //                     className={styles.chatMessageAvatare}
    //                   />
    //                   <Typography sx={{ fontSize: 14 }}>{BotName}</Typography>
    //                 </Container>
    //                 <ListItemText
    //                   align="left"
    //                   className={styles.chatMessage}
    //                   sx={{ backgroundColor: "#EEEBEB" }}
    //                 >
    //                   {/* {defaultMessages ? (
    //                     <>
    //                       <Typography sx={{ fontSize: 15 }}>
    //                         {defaultMessages.WelcomeText}
    //                       </Typography>
    //                       <List className={styles.defaultQuestionsList}>
    //                         <ListItem disablePadding>
    //                           <ListItemButton>
    //                             <ListItemText
    //                               primary={defaultMessages.Question1}
    //                             />
    //                           </ListItemButton>
    //                         </ListItem>
    //                         <ListItem disablePadding>
    //                           <ListItemButton>
    //                             <ListItemText
    //                               primary={defaultMessages.Question2}
    //                             />
    //                           </ListItemButton>
    //                         </ListItem>
    //                         <ListItem disablePadding>
    //                           <ListItemButton>
    //                             <ListItemText
    //                               primary={defaultMessages.Question3}
    //                             />
    //                           </ListItemButton>
    //                         </ListItem>
    //                       </List>
    //                     </>
    //                   ) : ( */}
    //                     <>
    //                       <Typography sx={{ fontSize: 15 }}>
    //                         Welcome to Conversational AI! I am your bot, your
    //                         assistant.
    //                       </Typography>
    //                     </>
    //                   {/* )} */}
    //                 </ListItemText>
    //               </Grid>
    //             </Grid>
    //           </ListItem>

    //           {chat.map((chatObject, index) => (
    //             <React.Fragment key={index}>
    //               <ListItem key="2" sx={{ padding: 1, width: "100%" }}>
    //                 <Grid container>
    //                   <Grid item xs={12}>
    //                     <Container
    //                       className={styles.chatAvatareContainer}
    //                       sx={{ justifyContent: "flex-end", gap: "0.2vw" }}
    //                     >
    //                       <Typography sx={{ fontSize: 14 }}>{userName}</Typography>
    //                       <AccountCircleIcon
    //                         sx={{ float: "right" }}
    //                         className={styles.chatMessageAvatare}
    //                       />
    //                     </Container>
    //                     <ListItemText
    //                       align="right"
    //                       className={styles.chatMessage}
    //                       sx={{ float: "right" }}
    //                     >
    //                       <Typography sx={{ fontSize: 15 }}>
    //                         {chatObject.user}
    //                       </Typography>
    //                     </ListItemText>
    //                   </Grid>
    //                 </Grid>
    //               </ListItem>
    //               <ListItem key="3" sx={{ padding: 1 }}>
    //                 <Grid container>
    //                   <Grid item xs={12}>
    //                     <Container
    //                       className={styles.chatAvatareContainer}
    //                       sx={{ justifyContent: "flex-start", gap: "0.2vw" }}
    //                     >
    //                       <AccountCircleIcon
    //                         sx={{ float: "left" }}
    //                         className={styles.chatMessageAvatare}
    //                       />
    //                       <Typography sx={{ fontSize: 14 }}>{BotName}</Typography>
    //                     </Container>
    //                     <ListItemText
    //                       align="left"
    //                       className={styles.chatMessage}
    //                       sx={{ backgroundColor: "#EEEBEB" }}
    //                     >
    //                       <Typography sx={{ fontSize: 15 }}>
    //                         {chatObject.chatbot}
    //                       </Typography>
    //                     </ListItemText>
    //                   </Grid>
    //                 </Grid>
    //               </ListItem>
    //             </React.Fragment>
    //           ))}
    //           <div
    //             style={{ float: "left", clear: "both" }}
    //             ref={messagesEndRef}
    //           ></div>
    //         </List>
    //         <Divider />
    //         <Grid container sx={{}}>
    //           <Grid item xs={10}>
    //             <TextField
    //               id="question"
    //               label="Type Something"
    //               variant="filled"
    //               style={{ background: "white" }}
    //               onKeyPress={(event) => {
    //                 if (event.key === "Enter") {
    //                   AskQuestion();
    //                 }
    //               }}
    //               fullWidth
    //             />
    //           </Grid>
    //           <Grid xs={2} align="right">
    //             <Fab color="primary" aria-label="add" onClick={AskQuestion}>
    //               <SendIcon />
    //             </Fab>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </Container>
    <div>
      <Grid container className="py-4 px-[5%]">
        <div
          style={{ borderColor: "rgba(0, 0, 0, 0.40)" }}
          className="bg-white w-full rounded-tl-[16px] rounded-tr-[16px] rounded-bl-[6px] rounded-br-[16px] border"
        >
          <Grid
            item
            className="bg-gradiant h-auto text-white w-full pl-[10px] p-[10px] rounded-tl-[16px] rounded-tr-[16px]"
          >
            <img src="/avatare-bot-logo.svg" alt="icon" />
          </Grid>
          <Grid container className={styles.chatSection}>
            <Grid item sx={{ width: "100%" }}>
              <List className={styles.messageArea}>
                {chat.map((message, index) => (
                  <React.Fragment key={index}>
                    <ListItem key="1" sx={{ padding: 1 }}>
                      {message.user && (
                        <div className="flex  ml-auto">
                          <ListItemText
                            align="left"
                            className={styles.chatMessageUser}
                            sx={{ float: "right" }}
                          >
                            <Typography sx={{ fontSize: 15 }}>
                              <pre className="text-wrap">{message.user}</pre>
                            </Typography>
                          </ListItemText>
                          <Avatar size={40} src="" />
                        </div>
                      )}
                    </ListItem>
                    <ListItem key="2" sx={{ padding: 1 }}>
                      {message.bot && (
                        <Grid container>
                          <Grid item xs={12}>
                            <div className="flex gap-2 w-fit">
                              <img src="/bot-icon.svg" />
                              <ListItemText
                                align="left"
                                className={styles.chatMessage}
                                sx={{ backgroundColor: "#F2F2F2" }}
                              >
                                <Typography sx={{ fontSize: 15 }}>
                                  <pre className="text-wrap">{message.bot}</pre>
                                </Typography>
                              </ListItemText>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </ListItem>
                  </React.Fragment>
                ))}

                <div ref={messagesEndRef} />
                {chatLoading && (
                  <ListItem sx={{ padding: 1 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="flex gap-2 w-fit">
                          <img src="/bot-icon.svg" />
                          <ListItemText
                            align="left"
                            className={styles.chatMessage}
                            sx={{ backgroundColor: "#F2F2F2" }}
                          >
                            <span className="ellipsis-anim flex gap-1">
                              <span>
                                <img
                                  src="/dot.svg"
                                  width={16}
                                  height={16}
                                  alt="e"
                                />
                              </span>
                              <span>
                                <img
                                  src="/dot.svg"
                                  width={16}
                                  height={16}
                                  alt="e"
                                />
                              </span>
                              <span>
                                <img
                                  src="/dot.svg"
                                  width={16}
                                  height={16}
                                  alt="e"
                                />
                              </span>
                            </span>
                          </ListItemText>
                        </div>
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
          <Divider />
        </div>
        <div
          style={{ borderColor: "rgba(0, 0, 0, 0.40)" }}
          className="flex items-center justify-between mt-4 py-4 rounded-[50px] border-[1px] w-full  bg-white"
        >
          <div className="ml-[3%] w-[90%]">
            <textarea
              id="question"
              value={que}
              onChange={(e) => setQue(e.target.value)}
              placeholder="Write your message here"
              fullWidth
              className="border-none outline-none rounded-xl whitespace-pre-wrap resize-none bg-white w-full"
              disabled={disableTextInput || fetching}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  AskQuestion(event.target.value);
                }
              }}
            />
          </div>
          <div className="mx-auto flex gap-4 mr-[3%] mb-[3%]">
            <div
              aria-label="send"
              disabled={fetching || disableTextInput}
              onClick={AskQuestion}
            >
              <img src="/sendIcon.svg" />
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Chat;

// import React from "react";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
// import TextField from "@mui/material/TextField";
// import Typography from "@mui/material/Typography";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import { ListItemButton } from "@mui/material";
// import Container from "@mui/material/Container";
// import Fab from "@mui/material/Fab";
// import SendIcon from "@mui/icons-material/Send";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import styles from "./Chatbox.module.css";
// import swal from "sweetalert";

// const Chat = ({ SelectedPretrainedModel, BotId, BotName, userName, chat, setChat }) => {
//   /* eslint eqeqeq: 0 */

//   const [defaultMessages, setDefaultMessages] = React.useState(null)
//   const [newSession, setNewSession] = React.useState(true);
//   const messagesEndRef = React.createRef();

//   const AskQuestion = () => {
//     var question = document.getElementById("question").value;
//     if (question.trim().length === 0 ){
//       swal({
//         title: "Oh Snap!",
//         text: "please type something!",
//         icon: "error",
//         button: "Dismiss!",
//       })}
//       else{
//     setChat([...chat, { user: question, chatbot: "..." }]);
//     if (question) {
//       let body = {
//         BotId: BotId,
//         Question: question,
//         new_session: newSession
//       };
//       console.log(body);
//       fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/query/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "*/*",
//           Authorization: "Bearer " + localStorage.getItem("token"),
//         },
//         body: JSON.stringify(body),
//         cache: "default",
//       })
//         .then(function (response) {
//           console.log("resp :", response.json());
//           setNewSession(false)
//           return response.json();
//         })
//         .then(function (data) {
//           console.log("data",data);
//           // console.log(chat);
//           if (chat == []) {
//             // console.log("If");
//             var chatData = [{ user: question, chatbot: "..." }];
//           } else {
//             var chatData = [...chat, { user: question, chatbot: "..." }];
//           }
//           // console.log("chatData: ", chatData);
//           const last = chatData[chatData.length - 1];
//           // console.log("last: ", last);
//           last.chatbot = data.message;
//           chatData[chatData.length - 1] = last;
//           // console.log(last);
//           setChat(chatData);
//           document.getElementById("question").value = "";
//           return data;
//         });
//     }
//   }
// }
//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };
//   React.useEffect(() => {
//     console.log("BotID is: ", BotId);
//     console.log("Pre trained models are: ", SelectedPretrainedModel);
//     BotId!=1&&fetch(`${process.env.REACT_APP_API_ROOT}/api/data/defaultresponses/?BotId=` + BotId, {
//             method: "GET",
//             headers: {
//               Authorization: 'Bearer ' + localStorage.getItem('token')
//             },
//             cache: "default",
//           })
//             .then(function (response) {
//               console.log("resp :", response);
//               if(response.status==400){
//                 console.log("resp :", response);
//                 setDefaultMessages(null);
//                 return null
//               }
//               return response.json();
//             })
//             .then(function (data) {
//               console.log("data : :", data);
//               if (data){
//                 console.log("data = :", data);
//                 setDefaultMessages(data.message[0]);
//               }
//             })
//     scrollToBottom();
//   }, [BotId, SelectedPretrainedModel, chat]);

//   return (
//     <Container className={styles.chatbox}>
//       <Grid container>
//         <Grid item className={styles.headerContainer}>
//           <AccountCircleIcon className={styles.accountCircleIcon} />
//           <Typography variant="h6" className="header-message">
//             Chat with {BotName}
//           </Typography>
//           <RefreshIcon onClick={()=>setChat([])} sx={{ ml: "auto", mr:"20px"}} />
//         </Grid>
//         <Grid container component={Paper} className={styles.chatSection}>
//           <Grid item >
//             <List className={styles.messageArea}>
//               <ListItem key="1" sx={{ padding: 1 }}>
//                 <Grid container>
//                   <Grid item xs={12}>
//                     <Container className={styles.chatAvatareContainer}>
//                       <AccountCircleIcon
//                         sx={{ float: "left" }}
//                         className={styles.chatMessageAvatare}
//                       />
//                       <Typography sx={{ fontSize: 14 }}>{BotName}</Typography>
//                     </Container>
//                     <ListItemText
//                       align="left"
//                       className={styles.chatMessage}
//                       sx={{ backgroundColor: "#EEEBEB" }}
//                     >
//                       {defaultMessages? <>
//                       <Typography sx={{ fontSize: 15 }}>
//                         {defaultMessages.WelcomeText}
//                       </Typography>
//                       <List className={styles.defaultQuestionsList}>
//                         <ListItem disablePadding>
//                           <ListItemButton>
//                             <ListItemText primary={defaultMessages.Question1} />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem disablePadding>
//                           <ListItemButton>
//                             <ListItemText primary={defaultMessages.Question2} />
//                           </ListItemButton>
//                         </ListItem>
//                         <ListItem disablePadding>
//                           <ListItemButton>
//                             <ListItemText primary={defaultMessages.Question3} />
//                           </ListItemButton>
//                         </ListItem>
//                       </List>
//                       </>:
//                       <>
//                       <Typography sx={{ fontSize: 15 }}>
//                         Welcome to Conversational AI! I am your bot, your
//                         assisstant.
//                       </Typography></>}
//                     </ListItemText>
//                   </Grid>
//                 </Grid>
//               </ListItem>

//               {chat.map((chatObject) => (
//                 <>
//                   <ListItem key="2" sx={{ padding: 1, width: "100%" }}>
//                     <Grid container>
//                       <Grid item xs={12}>
//                         <Container
//                           className={styles.chatAvatareContainer}
//                           sx={{ justifyContent: "flex-end", gap: "0.2vw" }}
//                         >
//                           <Typography sx={{ fontSize: 14 }}>{userName}</Typography>
//                           <AccountCircleIcon
//                             sx={{ float: "right" }}
//                             className={styles.chatMessageAvatare}
//                           />
//                         </Container>
//                         <ListItemText
//                           align="right"
//                           className={styles.chatMessage}
//                           sx={{ float: "right" }}
//                         >
//                           <Typography sx={{ fontSize: 15 }}>
//                             {chatObject.user}
//                           </Typography>
//                         </ListItemText>
//                       </Grid>
//                     </Grid>
//                   </ListItem>
//                   <ListItem key="3" sx={{ padding: 1 }}>
//                     <Grid container>
//                       <Grid item xs={12}>
//                         <Container
//                           className={styles.chatAvatareContainer}
//                           sx={{ justifyContent: "flex-start", gap: "0.2vw" }}
//                         >
//                           <AccountCircleIcon
//                             sx={{ float: "left" }}
//                             className={styles.chatMessageAvatare}
//                           />
//                           <Typography sx={{ fontSize: 14 }}>{BotName}</Typography>
//                         </Container>
//                         <ListItemText
//                           align="left"
//                           className={styles.chatMessage}
//                           sx={{ backgroundColor: "#EEEBEB" }}
//                         >
//                           <Typography sx={{ fontSize: 15 }}>
//                             {chatObject.chatbot}
//                           </Typography>
//                         </ListItemText>
//                       </Grid>
//                     </Grid>
//                   </ListItem>
//                 </>
//               ))}
//               <div
//                 style={{ float: "left", clear: "both" }}
//                 ref={messagesEndRef}
//               ></div>
//             </List>
//             <Divider />
//             <Grid container sx={{ }}>
//               <Grid item xs={10}>
//                 <TextField
//                   id="question"
//                   label="Type Something"
//                   // id="filled-search"
//                   variant="filled"
//                   style={{background:"white"}}
//                   onKeyPress={(event) => {
//                     if (event.key === "Enter") {
//                       AskQuestion();
//                     }
//                   }}
//                   fullWidth
//                 />
//               </Grid>
//               <Grid xs={2} align="right">
//                 <Fab color="primary" aria-label="add" onClick={AskQuestion}>
//                   <SendIcon />
//                 </Fab>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default Chat;
