import * as React from "react";
import { Select } from "antd"
import Chatbox from "../../components/Classroom/Chatbox/Chatbox";
import { TopBar } from "../../components/CommonComponent/Topbar";

const TestMyAvatare = () => {
  const [avatares, setAvatares] = React.useState([]);
  const [pretrainedModels, setPretrainedModels] = React.useState([]);
  const [selectedAvatare, setSelectedAvatare] = React.useState(1);
  const [chat, setChat] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [selectedPretrainedModel, setSelectedPretrainedModel] =
    React.useState("Elastic Search");
  const [botName, setBotName] = React.useState("Zofi");
  const userName = localStorage.getItem("email").split("@")[0];
  const getTrainedBots = async () => {
    let userId = localStorage.getItem("UserId");
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/bot/gettrainedbots/?UserId=${userId}`,
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    ).then((data) => data.json());
  };

  const getModelsList = async () => {
    return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/modelslist/`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }).then((data) => data.json());
  };


  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        const trainedBots = await getTrainedBots();
        const modelList = await getModelsList();
        if (mounted) {
          setAvatares(trainedBots.message);
          const botOptions = Array.isArray(trainedBots.message)
            ? trainedBots.message.map((bot) => ({
                label: (
                  <div className="flex items-center gap-2">
                    <span>
                      <img src="/default-bot-icon.svg" />
                    </span>
                    {bot?.BotId?.BotName || "Unnamed Bot"}
                  </div>
                ),
                value: bot?.BotId?.BotId || "",
              }))
            : [{ label: "No Avatar", value: null }];
          setOptions(botOptions);
          setPretrainedModels(modelList.message);
          setSelectedPretrainedModel(modelList.message[0]?.BotId?.BotId);
        }
      } catch (error) {
        // console.error("Error fetching data:", error);

      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    console.log("avatares", avatares);
  }, [avatares]);

  const handleAvatareChange = (value) => {
    setSelectedAvatare(value);
    setChat([]);
    return true;
  };

  return (
    <>
      <TopBar text={"Test My Avatare"} />
      <div className="flex gap-4 m-6">
        <div className="w-[70%] bg-white p-6 rounded-sm ">
          <Chatbox
            SelectedPretrainedModel={selectedPretrainedModel}
            BotId={selectedAvatare}
            BotName={botName}
            userName={userName}
            chat={chat}
            setChat={setChat}
          />
        </div>
        <div className="px-6 w-[30%]">
          <div>
            <p className="text-xl font-medium">Select your trained Avatare</p>
            <p className="mt-1 font-normal">
              Select one trained Avatare to test this{" "}
            </p>
            <Select
               style={{
                height: "70px",  
              }}
              className="mt-6 w-full  focus:outline-none focus:border-[#000]"
              placeholder={
               "Select your trained Avatare"
              }
              optionFilterProp="label"
              onChange={handleAvatareChange}
              onSearch={handleAvatareChange}
              options={options}
            />
          </div>
          {/* <Box
            sx={{
              bgcolor: "#E1F4FF",
              height: "25vh",
              // width: "20vw!important",
              mt: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography fontSize={20} sx={{ mt: 2 }}>
              Select from a pre-trained model
            </Typography>
            <FormControl
              sx={{
                m: 1,
                minWidth: 300,
                height: 50,
                backgroundColor: "white",
              }}
            >
              <InputLabel id="demo-simple-select-label" sx={{ fontSize: 12 }}>
                Select a pre-trained model
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select from a pre-trained model"
                width="100%"
                maxHeight="10px"
                value={selectedPretrainedModel}
                onChange={(e) => setSelectedPretrainedModel(e.target.value)}
              >
                {pretrainedModels.map((model) => (
                  <MenuItem value={model.value}>{model.key}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}
        </div>
      </div>

      {/* <Button
          variant="contained"
          size="large"
          endIcon={<ArrowRightAltIcon />}
          sx={{
            my: 4,
            ml: "auto",
            width: 250,
            padding: 1,
            backgroundColor: "#295598",
            fontSize: 14,
            fontWeight: "bold",
            color: "#ffffff",
            position: "relative",
            float: "left",
          }}
          onClick={scoreAndRating}
        >
          Scores and Ratings
        </Button> */}
    </>
  );
};

export default TestMyAvatare;
