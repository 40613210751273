import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";
import styles from "./ChatboxNew.module.css";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import { Select, Spin, message } from "antd";
import FileProgress from "../../common/FileProgress";
import { Option } from "antd/es/mentions";
import { useSidebar } from "../../common/SidebarContext";

const Chat = ({ chat, setChat, index, newChat, ...rest }) => {
  const {selectedProject}=useSidebar();
  const [originalData, setOriginalData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editAbleMessage, setEditAbleMessage] = useState("");
  const [oldQuestion, setOldQuestion] = useState("");
  const [oldAnswer, setOldAnswer] = useState("");

  const [result, setResult] = useState(null);
  const [generated, setGenerated] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [active, setActive] = useState([]);
  const [historyChat, setHistoryChat] = useState([]);
  const [stateData, setStateData] = useState(rest.page ? rest.page : "");
  const [disableTextInput, setDisableTextInput] = useState(false);
  const [disableFileInput, setDisableFileInput] = useState(true);
  const [fetching, setFetching] = useState(false);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [chatLoading, setChatLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [dependencies, setDependencies] = useState([]);
  const [que, setQue] = useState("");
  const [visible, setVisible] = useState(true);

  const generateExcel = () => {
    const link = document.createElement("a");
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.xlsx}`;
    link.download = "templated_output.xlsx";
    link.click();
  };

  const downloadFile = (filename, content, mimeType) => {
    const link = document.createElement("a");
    link.href = `data:${mimeType};base64,${content}`;
    link.download = filename;
    link.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ROOT_SEQUENTIAL}/api/ppt/stepper/`,
        {
          name: rest.title,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await axios.post(
        `${process.env.REACT_APP_API_ROOT_SEQUENTIAL}/api/ppt/get-depedencies/`,
        {
          name: rest.title,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(data?.data);
      setDependencies(data.data);

      if (rest?.data?.qa_list) {
        rest?.data?.qa_list.forEach((item) => {
          const qaObject = {
            bot: item.question,
            user: item.answer,
          };
          setHistoryChat((prevChat) => [...prevChat, qaObject]);
          setLoading(false);
        });

        setActive(rest?.data?.sub_section_name);
        setSessionId(rest?.data?.session_id);
      }

      setOriginalData(response.data);
    };

    if (newChat) {
      axios
        .post(
          process.env.REACT_APP_API_ROOT_SEQUENTIAL + "/api/ppt/get-answer/",
          {
            name: rest.title,
            answer: newAnswer,
            project_name:selectedProject,
          },
          {
            headers: {
              Authorization: `Bearer  ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (!response.data.is_last) {
            console.log(response);
            setNewQuestion(response.data.response);
            setCurrentIndex(response?.data?.current_section);
            let currentSection = response?.data?.current_section;
            if (!active.includes(currentSection)) {
              setActive([...active, currentSection]);
            }
            setSessionId(response.data.session_id);
            setChat([...chat, { bot: response.data.response }]);
            setLoading(false);
          } else {
            setIsLast(true);
            setCurrentIndex(response?.data?.current_section);
            let currentSection = response?.data?.current_section;
            if (!active.includes(currentSection)) {
              setActive([...active, currentSection]);
            }
            setSessionId(response.data.session_id);
            setResult(response.data.response);

            setGenerated(true);
            setFetching(false);
            setChat([
              ...chat,
              {
                bot: "I have generated a downloadable template of your work. Please see downloads below",
              },
            ]);
            setLoading(false);
          }
        });
    }

    fetchData();
  }, []);

  const handleSelect = (value) => {
    const selectedItem = dependencies?.find((item) => item.name === value);
    if (selectedItem) {
      GiveAnswers(selectedItem.summary);
    }
  };

  const GiveAnswers = async (que) => {
    if (que.trim().length === 0) {
      message.error("Please type something! input cannot be empty");
    } else {
      try {
        setChat([...chat, { user: que }]);
        setChatLoading(true);

        axios
          .post(
            process.env.REACT_APP_API_ROOT_SEQUENTIAL + "/api/ppt/get-answer/",
            {
              name: rest.title,
              session_id: sessionId,
              project_name:selectedProject,
              answer: que,
            },
            {
              headers: {
                Authorization: `Bearer  ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (!response.data.is_last) {
              console.log(response);

              setNewQuestion(response.data.response);
              setCurrentIndex(response?.data?.current_section);
              let currentSection = response?.data?.current_section;
              if (!active.includes(currentSection)) {
                setActive([...active, currentSection]);
              }
              setSessionId(response.data.session_id);
              setNewAnswer(que);
              setChatLoading(false);
              setChat([...chat, { bot: response.data.response, user: que }]);
            } else {
              setIsLast(true);
              setChatLoading(false);
              setChat([
                ...chat,
                {
                  user: que,
                  bot: "Thank you for your input. I'm going to generate a downloadable summary of your work. Please see downloads below",
                },
              ]);

              setTimeout(() => {
                setResult(response.data.response);
              }, 2000);

              let currentSection = response?.data?.current_section;
              if (!active.includes(currentSection)) {
                setActive([...active, currentSection]);
              }
              setGenerated(true);
              setFetching(false);
            }
          })
          .catch((error) => {
            console.error("Error sending response:", error);
            setChatLoading(false);
          });
        setQue("");
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const editMessage = async () => {
    if (editAbleMessage.trim().length === 0) {
      message.error("Please type something! input cannot be empty");
    } else {
      try {
        const updatedChat = chat.map((entry, index) => {
          if (index === chat.length - 1) {
            return { ...entry, user: editAbleMessage, bot: undefined };
          }
          return entry;
        });

        setChat(updatedChat);
        setEdit(false);
        setChatLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_API_ROOT_SEQUENTIAL}/api/ppt/edit-question/`,
          {
            name: rest.title,
            session_id: sessionId,
            old_answer: oldAnswer,
            new_answer: editAbleMessage,
            question: oldQuestion,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.data.is_last) {
          setNewQuestion(response.data.response);
          setCurrentIndex(response.data.current_section);
          const currentSection = response.data.current_section;
          if (!active.includes(currentSection)) {
            setActive((prevActive) => [...prevActive, currentSection]);
          }
          setSessionId(response.data.session_id);
          setNewAnswer(editAbleMessage);
          setChatLoading(false);
          const updatedWithBot = updatedChat.map((entry, index) => {
            if (index === chat.length - 1) {
              return { ...entry, bot: response.data.response };
            }
            return entry;
          });

          setChat(updatedWithBot);
        } else {
          setChatLoading(false);
          setIsLast(true);
          const finalChatUpdate = updatedChat.map((entry, index) => {
            if (index === chat.length - 1) {
              return {
                user: editAbleMessage,
                bot: "Thank you for your input. I'm going to generate a downloadable summary of your work. Please see downloads below",
              };
            }
            return entry;
          });

          setChat(finalChatUpdate);

          setTimeout(() => {
            setResult(response.data.response);
          }, 2000);

          const currentSection = response.data.current_section;
          if (!active.includes(currentSection)) {
            setActive((prevActive) => [...prevActive, currentSection]);
          }
          setGenerated(true);
          setFetching(false);
        }

        // Clear the editable message input
        setEditAbleMessage("");
      } catch (error) {
        console.error("Error occurred:", error);
        setChatLoading(false);
      }
    }
  };

  const handleEdit = (answer, question) => {
    console.log(question);
    setEditAbleMessage(answer);
    setOldQuestion(question);
    setOldAnswer(answer);
    setEdit(true);
  };

  return (
    <>
      {loading ? (
        <div className="w-full flex items-center justify-center h-screen">
          <Spin size="large" className="custom-spin" />{" "}
        </div>
      ) : (
        <div>
          <div className="w-full p-0 sticky top-0 z-10 bg-white overflow-x-auto">
            <FileProgress
              fileNames={originalData}
              currentIndex={currentIndex}
              active={active}
              chat={true}
              initialValue={0}
            />
          </div>
          <Grid container className="py-4 px-[16%] -z-[10]">
            <div
              style={{ borderColor: "rgba(0, 0, 0, 0.40)" }}
              className="bg-white w-full rounded-tl-[16px] rounded-tr-[16px] rounded-bl-[6px] rounded-br-[16px] border"
            >
              <Grid
                item
                className="bg-gradiant h-auto w-full pl-[10px] p-[10px] rounded-tl-[16px] rounded-tr-[16px]"
              >
                <div className="flex justify-between">
                  <div className="flex items-start gap-3">
                    <img src="/chat-header-logo.svg" width={50} />
                    <div className="flex flex-col items-start gap-1">
                      <div>
                        <h2 className="text-lg text-white font-medium ml-1">
                          {rest.title}
                        </h2>
                      </div>
                      <div className="bg-[#20556E] w-fit text-sm px-4 py-1 rounded-[20px] text-center font-normal text-white">
                        {rest.heading}
                      </div>
                    </div>
                  </div>
                  <div className="flex self-center pr-12">
                    {(rest.show || newChat) && (
                      <Select
                        onChange={handleSelect}
                        placeholder="Populate Summary"
                      >
                        {dependencies?.map((item) => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid container className={styles.chatSection}>
                <Grid item sx={{ width: "100%" }}>
                  {visible && (
                    <div className="relative bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md shadow-md">
                      <strong>⚠ Warning:</strong> Please follow the steps in
                      sequential order. Ensure each session is completed before
                      proceeding.
                      <button
                        onClick={() => setVisible(!visible)}
                        className="absolute top-2 right-2 text-red-700 "
                      >
                        ❌
                      </button>
                    </div>
                  )}

                  <List className={styles.messageArea}>
                    {historyChat &&
                      historyChat.map(
                        (chat, index) =>
                          stateData === "history" && (
                            <React.Fragment key={index}>
                              <ListItem key="2" sx={{ padding: 1 }}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div className="flex gap-2 w-fit">
                                      <img src="/bot-icon.svg" />
                                      <ListItemText
                                        align="left"
                                        className={styles.chatMessage}
                                        sx={{ backgroundColor: "#F2F2F2" }}
                                      >
                                        <Typography sx={{ fontSize: 15 }}>
                                          <pre className="text-wrap">
                                            {chat.bot}
                                          </pre>
                                        </Typography>
                                        {fileUrl &&
                                          chat.bot ===
                                            "Please download this document" && (
                                            <div>
                                              <Button
                                                variant="contained"
                                                sx={{
                                                  backgroundColor: "#295598",
                                                  px: "30px",
                                                  py: "10px",
                                                }}
                                              >
                                                <a
                                                  href={fileUrl}
                                                  download="presentation.pptx"
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "white",
                                                  }}
                                                >
                                                  Download
                                                </a>
                                              </Button>
                                            </div>
                                          )}
                                      </ListItemText>
                                    </div>
                                  </Grid>
                                </Grid>
                              </ListItem>
                              <ListItem key="1" sx={{ padding: 1 }}>
                                {chat.user && (
                                  <div className="flex justify-end w-full">
                                    <div className="flex items-center justify-center gap-4 w-fit">
                                      <ListItemText
                                        align="left"
                                        className={styles.chatMessage2}
                                        sx={{ float: "right" }}
                                      >
                                        <Typography sx={{ fontSize: 15 }}>
                                          <pre className="text-wrap">
                                            {chat.user}
                                          </pre>
                                        </Typography>
                                      </ListItemText>
                                      <Avatar size={40} src="" />
                                    </div>
                                  </div>
                                )}
                              </ListItem>
                            </React.Fragment>
                          )
                      )}
                    {chat.map((message, index) => (
                      <React.Fragment key={index}>
                        <ListItem key="1" sx={{ padding: 1 }}>
                          {message.user && (
                            <div className="flex justify-end w-full">
                              {edit ? (
                                index == chat.length - 1 ? (
                                  <div
                                    style={{
                                      borderColor: "rgba(0, 0, 0, 0.40)",
                                    }}
                                    className="flex items-center justify-between rounded-[4px] mt-4 py-2 border-[1px] w-full  bg-white"
                                  >
                                    <div className="ml-[3%] w-[90%]">
                                      <textarea
                                        value={editAbleMessage}
                                        onChange={(e) =>
                                          setEditAbleMessage(e.target.value)
                                        }
                                        placeholder="Write your message here"
                                        fullWidth
                                        className="border-none outline-none rounded-xl resize-none bg-white w-full"
                                        disabled={disableTextInput || fetching}
                                        onKeyDown={(event) => {
                                          if (
                                            event.key === "Enter" &&
                                            !event.shiftKey
                                          ) {
                                            event.preventDefault();
                                            editMessage();
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="mx-auto flex gap-4 mr-[3%] mt-[3%]">
                                      <button
                                        className="secondary p-2"
                                        onClick={() => setEdit(false)}
                                      >
                                        cancel
                                      </button>
                                      <button
                                        className="primary p-2 cursor-pointer"
                                        aria-label="send"
                                        disabled={fetching || disableTextInput}
                                        onClick={editMessage}
                                      >
                                        save
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center justify-center w-fit">
                                    <ListItemText
                                      align="left"
                                      className={styles.chatMessageUser}
                                      sx={{ float: "right" }}
                                    >
                                      <Typography sx={{ fontSize: 15 }}>
                                        <pre className="text-wrap">
                                          {message.user}
                                        </pre>
                                      </Typography>
                                    </ListItemText>
                                    <Avatar size={40} src="" />
                                  </div>
                                )
                              ) : (
                                <div className="flex items-center justify-center w-fit">
                                  {index == chat.length - 1 &&
                                    !chatLoading &&
                                    !isLast && (
                                      <img
                                        src="/edit-icon-black.png"
                                        className="cursor-pointer w-4 flex self-start mt-2 mr-0.5"
                                        onClick={() =>
                                          handleEdit(
                                            message.user,
                                            chat[index - 1].bot
                                          )
                                        }
                                      />
                                    )}

                                  <ListItemText
                                    align="left"
                                    className={styles.chatMessageUser}
                                    sx={{ float: "right" }}
                                  >
                                    <Typography sx={{ fontSize: 15 }}>
                                      <pre className="text-wrap">
                                        {message.user}
                                      </pre>
                                    </Typography>
                                  </ListItemText>
                                  <Avatar size={40} src="" />
                                </div>
                              )}
                            </div>
                          )}
                        </ListItem>
                        <ListItem key="2" sx={{ padding: 1 }}>
                          {message.bot && (
                            <Grid container>
                              <Grid item xs={12}>
                                <div className="flex gap-2 w-fit">
                                  <img src="/bot-icon.svg" />
                                  <ListItemText
                                    align="left"
                                    className={styles.chatMessage}
                                    sx={{ backgroundColor: "#F2F2F2" }}
                                  >
                                    <Typography sx={{ fontSize: 15 }}>
                                      <pre className="text-wrap">
                                        {message.bot}
                                      </pre>
                                    </Typography>
                                  </ListItemText>
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </ListItem>
                      </React.Fragment>
                    ))}

                    <div ref={messagesEndRef} />
                    {chatLoading && (
                      <ListItem sx={{ padding: 1 }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <div className="flex gap-2 w-fit">
                              <img src="/bot-icon.svg" />
                              <ListItemText
                                align="left"
                                className={styles.chatMessage}
                                sx={{ backgroundColor: "#F2F2F2" }}
                              >
                                <span className="ellipsis-anim flex gap-1">
                                  <span>
                                    <img
                                      src="/dot.svg"
                                      width={16}
                                      height={16}
                                      alt="e"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      src="/dot.svg"
                                      width={16}
                                      height={16}
                                      alt="e"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      src="/dot.svg"
                                      width={16}
                                      height={16}
                                      alt="e"
                                    />
                                  </span>
                                </span>
                              </ListItemText>
                            </div>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                  </List>
                </Grid>
              </Grid>
              <Divider />
            </div>
            {(rest.show || newChat) && (
              <div
                style={{ borderColor: "rgba(0, 0, 0, 0.40)" }}
                className="flex items-center justify-between mt-4 py-4 rounded-[50px] border-[1px] w-full  bg-white"
              >
                <div className="ml-[3%] w-[90%]">
                  <textarea
                    id="question"
                    value={que}
                    onChange={(e) => setQue(e.target.value)}
                    placeholder="Write your message here"
                    fullWidth
                    className="border-none outline-none rounded-xl whitespace-pre-wrap resize-none bg-white w-full"
                    disabled={disableTextInput || fetching}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && !event.shiftKey) {
                        event.preventDefault();
                        GiveAnswers(event.target.value);
                      }
                    }}
                  />
                </div>
                <div className="mx-auto flex gap-4 mr-[3%] mb-[3%]">
                  {/* <div>
                  <input
                    accept="image/png, image/svg, image/jpeg"
                    id="file-input"
                    type="file"
                    style={{ display: "none" }}
                    disabled={disableFileInput}
                  // onChange={handleFileChange}
                  />
                  <label htmlFor="file-input">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      aria-label="attach"
                    >
                      <img src="/attach-icon.svg" />
                    </div>
                  </label>
                </div> */}

                  <div
                    aria-label="send"
                    disabled={fetching || disableTextInput}
                    onClick={GiveAnswers}
                  >
                    <img src="/sendIcon.svg" />
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-center w-full items-center mt-4 mb-2  gap-4">
              {generated && result?.xlsx && (
                <button
                  onClick={generateExcel}
                  className="primary text-white p-2"
                >
                  Download Template
                </button>
              )}
              {generated && result?.docx && (
                <button
                  onClick={() =>
                    downloadFile(
                      "Summary.docx",
                      result.docx,
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    )
                  }
                  className="primary text-white p-2"
                >
                  Download Summary Doc
                </button>
              )}
              {generated && result?.pdf && (
                <button
                  onClick={() =>
                    downloadFile("Summary.pdf", result.pdf, "application/pdf")
                  }
                  className="primary text-white p-2"
                >
                  Download Summary Pdf
                </button>
              )}
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Chat;
