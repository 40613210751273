import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
import { Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FileCard from "../../components/Library/FileCard/FileCard";
import { useNavigate } from 'react-router-dom';
import Folders from '../Library/DataFolders';

const SelectSubjectsToTrain = () => {
    const [dataFolders, setDataFolders] = React.useState([])
    const [checkedIDs, setCheckedIDs] = React.useState([])
    const [fileFolders, setFileFolders] = React.useState([]) 
    const [manualFolders, setManualFolders] = React.useState([]);
    const [scoreFolders, setScoreFolders] = React.useState([]); 
    const UserId = localStorage.getItem("UserId")
    const navigate  = useNavigate()

    let { Id } = useParams()

    const getData = async () => {
        return fetch(
          `${process.env.REACT_APP_API_ROOT}/api/data/createDataset/?UserId=${UserId}&BotId=${Id}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
        ).then((data) => data.json());
      };

    React.useEffect(() => {
    let mounted = true;
    getData()
      .then(items => {
        setDataFolders(items.message)
        console.log("dataFolders are: " ,dataFolders)
        let fileFoldersArray = items.message.filter(function(item){
          return item.FolderType=="Files";
        });
        let manualFoldersArray = items.message.filter(function(item){
          return item.FolderType=="Manual";
        });
        let scoresFoldersArray = items.message.filter(function(item){
          return item.FolderType=="Scores";
        });
        setFileFolders(fileFoldersArray);
        setManualFolders(manualFoldersArray);
        setScoreFolders(scoresFoldersArray);
      })
    }, [])

    const next = () => {
        navigate('/classroom/selectModel/' + Id, {state: {DataIds: checkedIDs}})
    }

    
  return (
    <>
   <Folders id={Id} setCheckedIDs={setCheckedIDs} checkedIDs={checkedIDs} variant={"Train By Subject"} handleNext={next} />

    
      {/* <Container
        fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          // margin:"3vw",
          // width:"2000px"
        }}
      >
          <>{DataFolderScreen()}</>
        </Container> */}
        {/* <Button
              variant="contained"
              size="large"
              endIcon={<ArrowRightAltIcon />}
              sx={{
                my: 4,
                ml:"50vw",
                width:200,
                padding:1,
                backgroundColor: "#295598",
                fontSize:14,
                fontWeight: "bold",
                color: "#ffffff",
                position:"relative",
                float: "left"
              }}
              onClick={next}
            >
              Next
          </Button> */}
    </>
  )
  // function DataFolderScreen() {
  //   return (
  //     <div>
  //       <Container
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           my: 2,
  //           flexWrap: "wrap",
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontWeight: "bold",
  //             fontSize: "24px",
  //           }}
  //         >
  //            Please select a subject to train your avatare on!
  //         </Typography>
  //       </Container>
  //       <Box
  //         sx={{
  //           bgcolor: "#E1F4FF",
  //           height: "40vh",
  //           width: "65vw",
  //           my: 5,
  //           display: "flex",
  //           alignItems: "left",
  //           flexDirection: "row",
  //           flexWrap: "wrap",
  //           overflowY: "auto",
  //         }}
  //       >
  //         {fileFolders &&
  //           fileFolders.map((file) => (
  //             <FileCard
  //               name={file.Name}
  //               DataId={file.DataId}
  //               SetCheckedIDs={setCheckedIDs}
  //               checkedIDs={checkedIDs}
  //               variant={"SubjectsToTrain"}
  //               implementChecks={true}
  //               manualResponses={false}
  //             />
  //           ))}
  //       </Box>
  //       <Container
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           my: 2,
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontWeight: "bold",
  //             fontSize: "24px",
  //           }}
  //         >
  //           Manually Created Responses
  //         </Typography>
  //       </Container>
  //       <Box
  //         sx={{
  //           bgcolor: "#E1F4FF",
  //           height: "40vh",
  //           width: "65vw",
  //           my: 5,
  //           display: "flex",
  //           alignItems: "left",
  //           flexDirection: "row",
  //           flexWrap: "wrap",
  //           overflow: "auto",
  //         }}
  //       >
  //         {manualFolders &&
  //           manualFolders.map((file) => (
  //             <FileCard
  //               name={file.Name}
  //               DataId={file.DataId}
  //               implementChecks={true}
  //               variant={"SubjectsToTrain"}
  //               SetCheckedIDs={setCheckedIDs}
  //               checkedIDs={checkedIDs}
  //               manualResponses={true}
  //             />
  //           ))}
  //       </Box>
  //       <Container
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           my: 2,
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontWeight: "bold",
  //             fontSize: "24px",
  //           }}
  //         >
  //           Scores and Ratings
  //         </Typography>
  //       </Container>
  //       <Box
  //         sx={{
  //           bgcolor: "#E1F4FF",
  //           height: "40vh",
  //           width: "65vw",
  //           my: 5,
  //           display: "flex",
  //           alignItems: "left",
  //           flexDirection: "row",
  //           flexWrap: "wrap",
  //           overflow: "auto",
  //         }}
  //       >
  //         {scoreFolders &&
  //           scoreFolders.map((file) => (
  //             <FileCard
  //               name={file.Name}
  //               DataId={file.DataId}
  //               variant={"SubjectsToTrain"}
  //               implementChecks={true}
  //               SetCheckedIDs={setCheckedIDs}
  //               checkedIDs={checkedIDs}
  //               manualResponses={true}
  //             />
  //           ))}
  //       </Box>
  //     </div>
  //   );
  // }

}




export default SelectSubjectsToTrain