import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideNav from "../components/common/SideNav";
import { useSidebar } from "../components/common/SidebarContext";
import Popup from "../components/common/PopUp";
import {jwtDecode} from 'jwt-decode';
import { message } from "antd";

const Main = ({ setTotalCredits, totalCredits, changeCredits }) => {
  const navigate = useNavigate();
  const { isSidebarOpen, setIsSidebarOpen,setIsModalOpen,isModalOpen,fetched,setFetched } = useSidebar();
  const token = localStorage.getItem("token");
  const closeModal = () => setIsModalOpen(false);
  const [project,setProject]=useState("");

  const checkLogin = () => {
    return token && token !== 'null';
  };

  const handleCreate = () => {
    if (!project.trim()) {
      message.error("Project name is required");
      return;
    }
    if (/^\s/.test(project)) {
      message.error("Project name should not start with a space");
      return;
    }
    if (project.length > 20) {
      message.error("Project name should not be more than 20 characters");
      return;
    }
  
    fetch(`${process.env.REACT_APP_API_ROOT_SEQUENTIAL}/api/ppt/add-project/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_name: project }),
    })
      .then((res) => res.json())
      .then(() => message.success("Project created successfully"),setFetched(true),setIsModalOpen(!isModalOpen))
      .catch(() => message.error("Project creation error"));
  };
  
  useEffect(() => {
    const isLogin = checkLogin();
    if (!isLogin) {
      navigate('/login');
    }
      const yourToken = localStorage.getItem('token');
      if (!yourToken) {
        return;
      }
      try {
        const decodedToken = jwtDecode(yourToken);
        if (decodedToken && decodedToken.exp) {
          const expiryTime = decodedToken.exp;
          const currentTime = Math.floor(Date.now() / 1000);
    
          if (currentTime > expiryTime) {
            localStorage.removeItem('token');
            setTimeout(() => {
              message.error('Session expired. Please login again...', 2);
              navigate('/login');
            }, 400);
            return;
          }
        } else {
          console.log('Invalid token format or missing expiry claim');
        }
      } catch (error) {
        console.error('Error decoding or processing token:', error);
      }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

 

  return (
    <div className="relative flex w-full h-screen">
      <div 
        className={`fixed h-screen inset-y-0 left-0 z-50 overflow-y-auto bg-white shadow-lg transform ${
          isSidebarOpen ? 'translate-x-0 w-[280px]' : '-translate-x-full w-0'
        } transition-transform duration-300 ease-in-out lg:relative`}
      >
        <button
          className="absolute top-0 right-4 p-2 z-20"
          onClick={(e)=>setIsSidebarOpen(!isSidebarOpen)}
          aria-label="Close sidebar"
        >
          <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <SideNav setAllCredits={setTotalCredits} totalCredits={totalCredits} changeCredits={changeCredits} />
      </div>

     
      <div
        className={`flex-1 transition-all duration-300 ease-in-out ${
          isSidebarOpen ? '' : 'lg:ml-0'
        } overflow-y-auto`}
      >
       
        <Outlet />
        <Popup
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        text={"Give a name to new project"}
        placeholder={"Add project name here"}
        fromClone={true}
        avatareName={project}
        setAvatareName={setProject}
        handleClone={handleCreate}
        buttonTextOne={"Continue"}
       
      />
      </div>
    </div>
  );
};

export default Main;
