import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import {
  Typography,
  InputLabel,
  FormControl,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import swal from "sweetalert";
import { AccountCircle, DownloadForOffline } from "@mui/icons-material";
import { useRef } from "react";
import Loader from "../../components/common/Loader";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { message, Spin } from "antd";

const DallE = ({ totalCredits }) => {
  const userId = localStorage.getItem("UserId");
  const token = localStorage.getItem("token");

  const [prompt, setPrompt] = useState("");
  const [promptData, setPromptData] = useState(null);
  const [loader, setLoader] = useState(false);
  const downloadRef = useRef(null);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/research/dalle/?UserId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const dataa = await response.json();
        // setPromptData(dataa);

        console.log(dataa, "");
      } else {
        const dataa = await response.json();
        console.log("Error:", response.status, dataa);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePostPrompt = async () => {
    if(prompt.trim()===""){
      message.error("Please enter prompt to proceed")
    }
    setLoader(true);
    setPromptData(null);
    const data = {
      UserId: userId,
      prompt: prompt,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/research/dalle/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("result :", result);
        setPromptData(result);
        setLoader(false);
        fetchData();
        totalCredits();
      } else {
        const result = await response.json();
        if (result?.message == "You dont have sufficient credit points") {
          swal({
            title: "Oh Snap!",
            text: "you Don't have enough credits.",
            icon: "error",
            button: "Dismiss!",
          });
        }
        // console.log('result =:', result, response.status);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  function downloadBase64File(base64Data, contentType, fileName) {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  const downloadAllImages = () => {
    promptData?.message.forEach((imageData, index) => {
      const fileName = `image_${index + 1}.png`;
      downloadBase64File(imageData.base_64, "image/png", fileName);
    });
  };

  return (
    <>
      <TopBar text="Dall E" />
      <div className="bg-white p-6 h-screen m-8 text-center rounded-md">
        <h1 className="text-xl font-medium">Write Prompt </h1>
        <p className="mt-2 text-sm font-normal">Write any prompt to generate relevant images</p>
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Write your prompt here"
          style={{ border: "1px solid rgba(116, 116, 116, 0.50)" }}
          className="w-full p-4  mt-4 text-base rounded bg-white cursor-pointer custom-outline"
        />
        <button className="py-3 primary mt-4 w-[200px] cursor-pointer" onClick={handlePostPrompt}> {loader?<Spin  className="custom-spin" />:"Generate Images"}</button>
        {Array.isArray(promptData?.message) && (
            <div className="w-full flex items-center flex-wrap justify-between px-3 my-5" >
              {promptData?.message?.map((urls, index) => (
                <div key={index} className="w-[32%] relative">
                 
                  <DownloadForOffline
                    sx={{ color: "white", position: "absolute", right: "10px", top: "10px", cursor: "pointer" }}
                    onClick={() =>
                      downloadBase64File(urls?.base_64, 'image/png', 'image.png')}
                  />
                 
                  <img src={urls?.url} className="w-full h-full object-cover"  />
                </div>
              ))}
            </div>
          ) }
          <div className="flex items-center justify-center gap-4 pb-6">
            {
            Array.isArray(promptData?.message) && prompt !== null &&
            <button className="py-3 primary mt-4 w-[200px] cursor-pointer" onClick={handlePostPrompt}> Re Generate Images</button>
            }
            {promptData?.message &&
             <button className="py-3 primary mt-4 w-[200px] cursor-pointer" onClick={downloadAllImages}> Download All Images</button>
            }
          </div>
        
      </div>
    </>
    // <React.Fragment>
    //   <CssBaseline />
    //   <Container
    //     fixed
    //     sx={{
    //       "display": "flex",
    //       "flexDirection": "column"
    //     }}
    //   >
    //     <Breadcrumbs_component Left="Research & Development" Right="Dall-E" />
    //     <Box
    //       sx={{
    //         bgcolor: "#E1F4FF",
    //         minHeight: "40vh",
    //         height: "100%",
    //         width: "100%",
    //         my: 10,
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         flexDirection: "column",
    //       }}
    //     >
    //       <Typography fontSize={20} sx={{ m: 2 }} fontWeight="bold">
    //         AI model generating images from any prompt!
    //       </Typography>
    //       <FormControl sx={{
    //         m: 1,
    //         width: "80% !important",
    //         display: "flex",
    //         // justifyContent: "space-between",
    //         alignItems: "center",
    //         // flexDirection: "column",
    //       }}>
    //          <TextField
    //     id="input-with-icon-textfield"
    //     label="Write any prompt here"
    //     InputProps={{
    //       endAdornment: (
    //         <InputAdornment sx={{cursor:"pointer"}} onClick={handlePostPrompt} position="end">
    //           <SearchIcon />
    //         </InputAdornment>
    //       ),
    //     }}
    //     sx={{ width: "100%",bgcolor:"white" }}
    //     variant="outlined"
    //     onChange={(e) => setPrompt(e.target.value)}
    //   />
    //         {/* <TextField  id="outlined-basic" label="Write any prompt here" variant="outlined" sx={{ backgroundColor: "white", width: "100%" }} />
    //         <button onClick={handlePostPrompt} size="small" style={{ backgroundColor: "#295598", color: "white",  padding: "15px 0px", fontWeight: "bold", justify: "center", margin: "20px 3px", border: "none", borderRadius: "12px", cursor: "pointer" }}>Generate Image</button> */}
    //       </FormControl>
  
    //       </Box>
    //     </Box>
    //   </Container>
    // </React.Fragment>
  );
};

export default DallE;
