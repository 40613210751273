import React, { useEffect, useState } from "react";
import { getTrainedData } from "../../components/common/api";
import { AvatarFromScratchCard } from "../../components/MyAvatarNew/AvatarFromScratchCard";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { useNavigate } from "react-router-dom";
import DataNotAvailable from "../../components/common/DataNotAvailable";


const Integrations = () => {
  const navigate = useNavigate();

  const [avatares, setAvatares] = useState([])
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getTrainedData(setAvatares);
  }, [])

 console.log(avatares)
  
  const handleSearchChange = (e) => {
   
    setSearchQuery(e.target.value);
  };
  const handleCLick = (id) => {
    navigate("/operations/selectIntegration/" + id)
  };

  const filterItems = (items) => {
    return items.filter((item) =>
      item?.BotId?.BotName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  
  return (
    <>
     <div>
      <TopBar text="Integrations" />
      <div className="py-2 px-8 bg-white m-10 rounded-sm">
        
        {avatares.length > 0 ? <div>
        <div>
        <div className="flex w-full justify-between items-center mt-3 pr-2">
                <h2 className="text-xl font-medium text-black">
                  Select Bot
                </h2>
                <div>
                  <div className="relative w-[350px] bg-[#F7F8FC] rounded-[4px]">
                    <div className="absolute inset-y-0 top-0 left-0 pl-3 flex items-center pointer-events-none">
                      <img src="/search.svg" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search template here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="w-full p-2 pl-10 border-[0.5px] bg-[#F7F8FC] custom-outline"
                    />
                  </div>
                </div>
              </div>
              <p className=" text-black text-opacity-70 font-normal mb-2">
              Select the bot from below to integrate.
              </p>
              </div>
          <div className="mt-3.5 mb-20">
            <div className="flex gap-6 m-w-[50%] flex-wrap">
              {filterItems(avatares)?.map((data, key) => (
                <AvatarFromScratchCard handleCLick={handleCLick} fromIntegration={true} dotsIcon={false} key={key} data={data} />
              ))}
            </div>
          </div>
        </div>: <DataNotAvailable text="Looks like you don’t have any Avatare yet" subText="Let’s create the first one" handleClick={(e)=>navigate("/admin/createAvatar")} buttontext={"Let's create"}  />}
      </div>
    </div>
    </>
    
  );
};

export default Integrations;