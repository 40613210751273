

import React, { useEffect, useState } from "react";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { AvatarFromScratchCard } from "../../components/MyAvatarNew/AvatarFromScratchCard";
import { cloneAvatar, getData } from "../../components/common/api";
import Popup from "../../components/common/PopUp";
import { message } from "antd";
import DataNotAvailable from "../../components/common/DataNotAvailable";
import { useNavigate } from "react-router-dom";

const CloneAvatare = () => {
  const [avatares, setAvatares] = useState([]);
  const navigate=useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [avatareName, setAvatareName] = useState("");
  const [botId, setBotId] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
    setisOpen(false);
  };

  //api call

  useEffect(() => {
    getData(setAvatares);
  }, [isOpen]);

  const handleCLick = (id) => {
    console.log(id)
    setIsModalOpen(true);
    setBotId(id);
  };

  const handleClone = () => {
    console.log("hello")
    if (avatareName.trim() !== "") {
      if (avatareName.replace(/\s/g, "").length >= 30){
        message.error("Name should not be more than 30 characters");
      }
      else {
        const result = cloneAvatar(botId, avatareName, setisOpen,setIsModalOpen);
        console.log(result)
      }
    } else {
      message.error("Give a name before cloning avatare");
    }
  };
  return (
    <div>
      <TopBar text="Clone Avatare" />

      {avatares?.length > 0 ?<div className="px-8 pt-4">
        <h1 className="text-lg font-medium mt-3">
          Select the bot to make a clone of it
        </h1>
        <div className="mt-6 mb-20">
          <div className="flex gap-6 m-w-[50%] flex-wrap">
            {avatares?.map((data, key) => (
              <AvatarFromScratchCard
                key={key}
                handleCLick={handleCLick}
                data={data}
              />
            ))}
          </div>
          <Popup
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            avatareName={avatareName}
            setAvatareName={setAvatareName}
            placeholder={"add clone avatare name here"}
            fromClone={true}
            text={"Give a name to cloned Avatare"}
            buttonTextOne={"Clone Avatare"}
            handleClone={handleClone}
          />
          <Popup
            isOpen={isOpen}
            closeModal={closeModal}
            avatareName={avatareName}
            setAvatarName={setAvatareName}
            fromClone={true}
            text={"Avatare Cloned Successfully!"}
            desc={"Your avatare has been cloned successfully under name"}
          />
        </div>
      </div>:<DataNotAvailable text="Looks like you don’t have any Avatare yet" subText="Let’s create the first one" handleClick={(e)=>navigate("/admin/createAvatar")} buttontext={"Let's create"}  />}
    </div>
  );
};

export default CloneAvatare;
