import React, { createContext, useState, useContext, useEffect } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedProject, setSelectedProject] = useState("Default")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetched,setFetched]=useState(false);

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ROOT_SEQUENTIAL}/api/ppt/get-projects/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(res => res.json())
      .then(result => {
        setData(result);
        if (data.length === 1) {
          setSelectedProject(data[0].project);
        }
      })
   .catch(console.error);
  }, [fetched]);



  return (
    <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen ,selectedProject,setSelectedProject,data,isModalOpen,setIsModalOpen,fetched,setFetched}}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  return useContext(SidebarContext);
};
