"use client"

import { useState, useEffect, useRef } from "react"
import { useSidebar } from "./SidebarContext"
import Popup from "./PopUp";

export default function ProjectDropdown() {
  const [isOpen, setIsOpen] = useState(false)
  const {selectedProject,setSelectedProject,data,setIsModalOpen}=useSidebar();
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSelectProject = (project) => {
    setSelectedProject(project)
    setIsOpen(false)
  }

  const handleAddProject =()=>{
    setIsModalOpen(true);
  }
  return (
    <div className="w-full max-w-xs mt-2 relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between w-full px-4 py-2 text-white bg-transparent border border-white border-opacity-50"
      >
        <span className="text-xl font-medium">{selectedProject}</span>
        <span className="ml-2">
          <img
            src="/arrow_dropdown.svg"
            className={`transform transition-transform duration-200 ${isOpen ? "" : "rotate-180"}`}
          />
        </span>
      </button>
      {isOpen && (
        <div className="absolute w-full border border-t-0 border-white border-opacity-[0.30] bg-[#20556E] z-50">
         <ul>
  {data?.map((projectObj, index) => (
    <li key={index} className="border-b border-white border-opacity-[0.11] last:border-b-0">
      <button
        className={`w-full p-4 text-left text-white hover:bg-[#245c76] ${
          selectedProject === projectObj.project ? "bg-[#20556E]" : ""
        }`}
        onClick={() => handleSelectProject(projectObj.project)}
      >
        {projectObj.project}
      </button>
    </li>
  ))}
</ul>

          <button className="flex items-center w-full p-4 text-white hover:bg-[#245c76] border-t border-white border-opacity-[0.11]" onClick={handleAddProject}>
            <span className="mr-2">
              <img src="/plus-project-icon.svg" alt="Add project" />
            </span>
            <span>Add new project</span>
          </button>
        </div>
      )}
    </div>
  )
}

