import * as React from "react";
import ChatboxNew from "../../components/Classroom/ChatboxNew/ChatboxNew";

import { useLocation } from "react-router-dom";

const TestMyAvatareNew = () => {
  const location = useLocation();
  console.log(location)
  const { ...state } = location.state || {} ;

  const [avatares, setAvatares] = React.useState([]);
  const [pretrainedModels, setPretrainedModels] = React.useState([]);
  const [selectedAvatare, setSelectedAvatare] = React.useState(1);
  const [chat, setChat] = React.useState([]);
  const [selectedPretrainedModel, setSelectedPretrainedModel] =
    React.useState("Elastic Search");
  const [botName, setBotName] = React.useState("Zofi");
  const userName = localStorage.getItem("email").split("@")[0];

  const scoreAndRating = () => {
    window.location.href = "/classroom/scoreAndRating/" + selectedAvatare;
  };

  const getTrainedBots = async () => {
    let userId = localStorage.getItem("UserId");
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/bot/gettrainedbots/?UserId=${userId}`,
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    ).then((data) => data.json());
  };

  const getModelsList = async () => {
    // let UserId = "7ed282afb12842529c03dd069f0c83da ";
    return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/modelslist/`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }).then((data) => data.json());
  };


  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        const trainedBots = await getTrainedBots();
        const modelList = await getModelsList();
        if (mounted) {
          setAvatares(trainedBots.message);
          setSelectedAvatare(trainedBots.message[0]?.BotId?.BotId);
          setPretrainedModels(modelList.message);
          setSelectedPretrainedModel(modelList.message[0]?.BotId?.BotId);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error if needed
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    console.log("avatares", avatares);
  }, [avatares]);





  return (
    <div className="w-full">
      
      <div className="bg-[#F7F8FC]">
        <div>
          <ChatboxNew
            {...state}
            SelectedPretrainedModel={selectedPretrainedModel}
            BotId={selectedAvatare}
            BotName={botName}
            userName={userName}
            chat={chat}
            setChat={setChat}
          />
        </div>
      </div>
    </div>
  );
};

export default TestMyAvatareNew;
