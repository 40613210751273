import React, { useEffect, useState } from "react";
import { Typography, Menu, MenuItem, MenuList, Divider, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./AvatareModelTraining.module.css";
import AvatarCreditModel from "./AvatarCreditModel";
import MembersModel from "./MembersModel";

const AvatareModelTraining = ({
  BotId,
  name,
  description,
  DataIds,
  button_message,
  emoji,
  SetRerender,
  model,
  totalAvatars,
  SelectModel,
  getData
}) => {
  const [buttonMess, setButtonMess] = useState(button_message);
  const [rerender, setRerender] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading,setLoading]=useState(false)
  const [open, setOpen] = useState(false);
  const [openmember, setOpenmember] = useState(false);
  const [shareBotId, setShareBotId] = useState(null);
  const [modelStatus, setModelStatus] = useState();
  const [modelData, setModelData] = useState({});
  const [totalCredits, setTotalCredits] = useState();

  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('UserId');
  const currentWindow = window.location.href.split("/")[4];

  const botData = {
    BotId: BotId,
    BotName: name,
    BotDescription: description,
    emojiS3Link: emoji,
  };

  // Fetch total credits
  const fetchTotalCredits = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/getcredits/?Id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTotalCredits(data);
      } else {
        message.error('Failed to fetch credits');
      }
    } catch (error) {
      message.error(`Error: ${error.message}`);
    }
  };

  // Fetch bot training status
  const fetchBotTrainingStatus = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/trainbot/?BotId=${BotId}`, { 
        headers: { Authorization: `Bearer ${token}` } 
      });
      const data = await response.json();
      
      setButtonMess(data.message);
      
      if (data.message === "Training" || data.message === "In Queue") {
        // setTimeout(() => {
        //   setRerender(!rerender);
        // }, 5000);
      } else if (data.message === "Completed") {
        setButtonMess("Re-train");
      }
    } catch (error) {
      message.error('Failed to fetch training status');
    }
  };

  useEffect(() => {
    fetchTotalCredits();
    
    if (currentWindow !== "integrations" && currentWindow !== "analytics") {
      fetchBotTrainingStatus();
    } else if (currentWindow === "integrations") {
      setButtonMess("Integrate");
    } else if (currentWindow === "analytics") {
      setButtonMess("Analytics");
    }
  }, []);

  // Train bot handler
  const trainBot = async (body) => {
    setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/trainbot/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body),
        cache: "default",
      });

      const data = await response.json();

      if (data.message === "Task started") {
        if (typeof DataIds === "undefined" && currentWindow === "trainMyModel") {
          navigate("/admin/createAvatar");
        } else {
          typeof DataIds === "undefined"
            ? navigate("/classroom/trainMyModel", {
                state: { botData: botData, buttonMessage: "Training" },
              })
            : navigate("/classroom/trainBySubject");
        }
      } else if (data.message === "No dataset found to start training!") {
        message.error("No dataset found to start training!");
        setLoading(false);
      }
      message.success("Training Completed!");
      setOpen(false);
      setLoading(false);
    } catch (error) {
      message.error('Failed to start training');
      setLoading(false);
    }
  };

  // Handle click event
  const handleClick = async (e) => {
    switch (buttonMess) {

      case "Start Training":
      case "Training":
      case "Re-train":
        const body = {
          BotId: BotId,
          ModelType: model,
          UserId: userId,
          ...(DataIds && { DataIds }),
        };
        await trainBot(body);
        break;
      
    }
  };

  // Delete bot handler
  const deleteById = async (e) => {
    if (e) {
      e.stopPropagation();
    }
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?BotId=${botData.BotId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          method: "DELETE",
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("Bot has been deleted!");
        SetRerender(true);
        getData();
      } else {
        message.error("Bot could not be deleted!");
      }
    } catch (error) {
      message.error("Error deleting bot");
    }
  };

  // Check credits for training
  const postCredits = async () => {
    const data = {
      BotId: BotId,
      ModelType: model,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/checkcreditsfortraining/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        setModelData(result);
        setModelStatus(response.status);
        setOpen(true);
      } else {
        message.error(result?.message || "Failed to check credits");
      }
    } catch (error) {
      message.error('Error checking credits');
    }
  };

  // Commented out code section
  const commentedOutRender = () => {
    return (
      <div>
        {/* Status Banner Rendering */}
        {/* {buttonMess == "Re-train" || buttonMess == "Completed" ? (
          <div className={styles.bannerCompleted}>Completed!</div>
        ) : buttonMess == "Training" || buttonMess == "In Queue" ? (
          <>
            <div className={styles.bannerTraining}>Training!</div>
          </>
        ) : buttonMess == "Integrate" ? (
          <>
            <div className={styles.bannerCompleted}>Completed!</div>
          </>
        )
          : buttonMess == "Analytics" ? (
            <></>
          )
            :
            <><div className={styles.bannerStart}>Untrained!</div></>
        } */}

        {/* Alternative Card Rendering */}
        {/* <div
          onClick={(e) => { SelectModel(BotId) }}
          style={{ cursor: "pointer" }}
        >
          {buttonMess == "Training" ? (
            <>
              <div className="spinner-container" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <div className="loading-spinner" style={{ margin: "auto", display: "flex", justifyContent: "center", width: "100%" }}>
                  {emoji == null ? (
                    <>
                      <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", mx: "auto" }}>
                        <AccountCircleIcon className={styles.AccountCircleIcon} sx={{ mx: "auto" }} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", }}>
                        <img
                          alt=" "
                          className={styles.loaderImage}
                          src={emoji}
                          style={{ borderRadius: "50%", objectFit: "cover", height: "55px", width: "55px" }}
                        />
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {emoji ? (
                <>
                  <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <img alt=" " src={emoji} style={{ borderRadius: "50%", objectFit: "cover", height: "55px", width: "55px" }} />
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", mx: "auto" }}>
                    <AccountCircleIcon className={styles.AccountCircleIcon} />
                  </Box>
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.cardContent}>
          <Typography fontSize={16} fontWeight="bold">
            {name}
          </Typography>
        </div> */}
      </div>
    );
  };

  return (
    <>
      <AvatarCreditModel 
        open={open} 
        handleCloseModel={() => setOpen(false)} 
        modelData={modelData} 
        loading={loading}
        modelStaus={modelStatus} 
        setOpen={setOpen} 
        handleClick={handleClick} 
      />
      <MembersModel 
        openmember={openmember} 
        handleClosememberModel={() => setOpenmember(false)} 
        setOpenmember={setOpenmember} 
        shareBotId={shareBotId} 
      />
      <div>
        <div 
          onClick={() => SelectModel(BotId)}  
          className='flex flex-col justify-center  items-center gap-1.5 border border-gray-200 hover:border-[#497964] text-wrap m-h-[165px] bg-white rounded-md w-[203px] py-3 cursor-pointer'
        >
          <img 
            src={emoji || "/default-bot-icon.svg"} 
            className='w-[60px] h-[60px] mt-3 rounded-full' 
            alt={name}
          />
          <h1 className='text-lg text-black font-medium pb-5'>{name}</h1>
          {currentWindow === "selectModel" && (
            <button
              className="primary p-2 px-4"
              onClick={buttonMess === "Start Training" || buttonMess === "Re-train" || buttonMess === "Training" ? postCredits : handleClick}
            >
              {"Train"}
            </button>
          )}
        </div>
        {commentedOutRender()}
      </div>
    </>
  );
};

export default AvatareModelTraining;