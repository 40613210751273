import * as React from "react";
import ReactTyped from "react-typed";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ContentCopy, DoneAll } from "@mui/icons-material";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { message, Spin } from "antd";

const ChatGPT = ({ totalCredits, setChangeCredits }) => {
  const userId = localStorage.getItem("UserId");
  const token = localStorage.getItem("token");
  const [prompt, setPrompt] = React.useState("");
  const [promptData, setPromptData] = React.useState(null);
  const [stop, setStop] = React.useState(false);
  const [animationKey, setAnimationKey] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [reGenerate, setReGenerate] = React.useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/research/chatgpt/?UserId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data, "");
      } else {
        console.error("Error fetching data:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePostPrompt = async () => {
    if (!prompt.trim()) return;

    setLoading(true);
    setPromptData(null);
    setReGenerate(false);
    setChangeCredits(false);
    
    const data = { UserId: userId, prompt };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/research/chatgpt/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setPromptData(result);
        setStop(false);
        setAnimationKey((prevKey) => prevKey + 1);
        fetchData();
        totalCredits();
        setChangeCredits(true);
      } else {
        const result = await response.json();
        message.error(result?.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TopBar text="ChatGPT" />
      <div className="bg-white p-6 h-auto m-8 text-center rounded-md">
        <h1 className="text-xl font-medium">Ask anything!</h1>
        <p className="mt-2 text-sm font-normal">Write whatever you want to ask</p>
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Write your prompt here"
          className="w-full p-4 mt-4 text-base rounded bg-white border  cursor-pointer custom-outline"
        />

        <button
          className="py-2 px-3 primary mt-4 cursor-pointer"
          onClick={handlePostPrompt}
          disabled={loading}
        >
          {loading ? <Spin className="custom-spin" /> : "Ask Away!"}
        </button>

        {promptData && (
          <div className="mt-4 text-left bg-gray-100 p-6 rounded relative">
            <ReactTyped
              strings={[promptData?.message]}
              typeSpeed={10}
              cursorChar="|"
              showCursor={true}
              stopped={stop}
              key={animationKey}
              onComplete={() => setReGenerate(true)}
              style={{ whiteSpace: "pre-wrap", padding: "10px" }}
            />
            <CopyToClipboard text={promptData?.message} onCopy={handleCopy}>
              <button className="absolute bottom-2 right-2 bg-transparent border-none cursor-pointer">
                {copied ? <DoneAll className="text-green-500" /> : <ContentCopy />}
              </button>
            </CopyToClipboard>
          </div>
        )}

        {loading && (
          <button
            onClick={() => {
              setStop(true);
              setPromptData(null);
              setLoading(false);
            }}
            className="primary py-2 px-3 mt-4"
          >
            Stop
          </button>
        )}

        {reGenerate && !loading && (
          <button onClick={handlePostPrompt} className="primary py-2 px-2 mt-4">
            Re Generate
          </button>
        )}
      </div>
    </>
  );
};

export default ChatGPT;
