import React from 'react'
import Container from "@mui/material/Container";
import AvatareModelTraining from "../../Administration/AvatareModelTraining/AvatareModelTraining";import { Typography } from '@mui/material';
;

const AvataresContainer = ({ avatares,getData, SetRerender, trainmymodel,totalCredits,setChangeCredits,filterItems }) => {
    const SelectModel = (botId) => {
        if (window.location.href.split("/")[4] == "trainBySubject") {
            window.location.href = "/classroom/selectSubjectsToTrain/" + botId
        }
        else {
            window.location.href = "/classroom/selectModel/" + botId
        }
    }
    React.useEffect(() => {
    }, [])
    return (
        <div className='flex gap-3 flex-wrap'>
          
          {avatares?.map((avatare) => (
            <div >
              <AvatareModelTraining
                AllCredits={totalCredits}
                trainmymodel={trainmymodel}
                BotId={avatare.BotId}
                name={avatare.BotName}
                description={avatare.BotDescription}
                button_message={avatare.buttonMess}
                emoji={avatare?.emojiS3Link}
                SetRerender={SetRerender}
                SelectModel={SelectModel}
                setChangeCredits={setChangeCredits}
                getData={getData}
              />
            </div>
          )
        )}
      </div>
    )
}

export default AvataresContainer