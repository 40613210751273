
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactMic } from 'react-mic';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';

function AudioRecorder({ onRecordingComplete, setAudioFile }) {
  const [isRecording, setIsRecording] = useState(false);
  const [duration, setDuration] = useState(0);
  const [file, setFile] = useState(null);
  const onStart = (e) => {

    setIsRecording(true);
  }

  const onStop = (recordedBlob) => {
    setIsRecording(false);
    const startTimeInSeconds = recordedBlob.startTime / 1000;
    const stopTimeInSeconds = recordedBlob.stopTime / 1000;
    const timeDuration = stopTimeInSeconds - startTimeInSeconds
    const audioFile = new File([recordedBlob.blob], 'recorded_audio.mp3', {
      type: 'audio/mpeg',
      lastModified: new Date().getTime(),
      size: recordedBlob.blob.size,
    });
    
    console.log('audio File ::: ', audioFile);
    setAudioFile({ file: audioFile, duration: timeDuration });
    setFile(audioFile);
  }


  const onData = (recordedBlob) => {
   
  }

  return (
    <>
    <div className="w-full flex flex-col justify-center items-center gap-1.5">
    <h1 className="font-medium text-lg">Record your Voice</h1>
    <p>you can record your real-time audio</p>
    <div
      style={{ borderColor: "rgba(0, 0, 0, 0.20)" }}
      className="mt-[3%] w-full flex flex-col justify-center items-center py-8 gap-2 border border-dashed cursor-pointer border-spacing-60"
      aria-label="attach"
     
    >
      <img src="/mic-icon.svg" className='w-24 mt-4'/>
      <ReactMic
        record={isRecording}
        visualSetting="sinewave"
        strokeColor='#6CA6C4'
        onStart={onStart}
        onStop={onStop}
        onData={onData}
      />
     
    </div>
      <div className='flex mt-3'>
      {!isRecording ? <button className='primary p-3' onClick={onStart}>
       START RECORDING
      </button>:
      <button className='primary p-3' onClick={onStop}>
        STOP RECORDING
      </button>}
      </div>
      
    </div>
    
  </>
  );
}

export default AudioRecorder;
