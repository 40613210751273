import React, { useEffect, useRef, useState } from "react";
import { TopBar } from "../../../components/CommonComponent/Topbar";
import { MyAvatarCard } from "../../../components/MyAvatarNew/MyAvatarCard";
import { message, Pagination, Spin } from "antd";
import DataNotAvailable from "../../../components/common/DataNotAvailable";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "../../../components/common/SidebarContext";

const TemplateHistory = () => {
  const {selectedProject} =useSidebar();
  const navigate = useNavigate();
  const [isOpenDropdown, setIsOpenDropdown] = useState({ playbook: false, email: false });
  const [playBooks, setPlayBooks] = useState([]);
  const [emails, setEmails] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const wrapperRef = useRef(null);
  const wrapperRef2 = useRef(null);
  const [allHistory, setAllHistory] = useState([]);
  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayHistory, setDisplayHistory] = useState([]);
  const [count, setCount] = useState(0);
  const [disableChatButton, setDisableChatButton] = useState(false);
  const [page, setPage] = useState(1);
  const recordsPerPage = 10; // Number of records per page
  const [searchQuery, setSearchQuery] = useState({ playbook: "", email: "", date: "" });

  const handleClick = () => navigate("/sequential-startup/templates-libray");

  const handleSelect = (type, value) => {
    setSearchQuery({ ...searchQuery, [type]: value.toLowerCase() });
    setIsOpenDropdown({ ...isOpenDropdown, [type]: false });
    setHighlightedIndex(-1);
  };

  const toggleDropdown = (type) => {
    if(type === "playbook"){
      setPlayBooks(playBooks)
    }
    else{
      setEmails(emails)
    }
    setIsOpenDropdown({ ...isOpenDropdown, [type]: !isOpenDropdown[type] });
    setHighlightedIndex(-1);
  };

  const handleMouseEnter = (index) => setHighlightedIndex(index);
  const handleMouseLeave = () => setHighlightedIndex(-1);

  const downloadChats = async (type) => {
    setDisableChatButton(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT_SEQUENTIAL}/api/ppt/download-all-${type}/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ session_ids: session }),
        }
      );

      if (!response.ok) {
        message.error("Error in downloading");
        return;
      }

      const data = await response.json();
      if (data.zip) {
        const blob = new Blob([new Uint8Array(atob(data.zip).split("").map(c => c.charCodeAt(0)))], { type: "application/zip" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${type}.zip`;
        a.click();
      } else {
        message.error("No downloads found in the response");
      }
    } catch (error) {
      message.error("Error in downloading");
    } finally {
      setDisableChatButton(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT_SEQUENTIAL}/api/ppt/delete-history/${id}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (!response.ok) throw new Error("Failed to delete the record");
      message.success("Successfully deleted");
      setAllHistory(prev => prev.filter(item => item.session_id !== id));
    } catch (error) {
      message.error("Failed to delete the record");
    }
  };

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_API_ROOT_SEQUENTIAL}${
          localStorage.getItem("email") === "superadmin@avatare.com"
            ? "/api/ppt/get-all-history/"
            :  `/api/ppt/get-history/?project_name=${selectedProject}`
        }`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setAllHistory(data?.history || []);
          setLoading(false);
          setCount(data?.history?.length || 0);
          const playbooks = Array.from(
            new Set(data?.history?.map((item) => item.section_name))
          );
          const emails = Array.from(
            new Set(data?.history?.map((item) => item.email).filter(Boolean))
          );  
          setPlayBooks(playbooks);
          setEmails(emails);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          message.error("Failed to fetch data");
        });
    };
    

   
    //   setLoading(true);
    //   try {
    //     fetch(
    //       process.env.REACT_APP_API_ROOT +
    //         ${
    //           localStorage.getItem("email") === "superadmin@avatare.com"
    //             ? "/api/ppt/get-all-history/"
    //             : "/api/ppt/get-history/"
    //         },
    //       {
    //         headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //       }
    //     );
    //     const data = await response.json();
    //     const history = data?.history || [];
    //     setAllHistory(history);
    //     setCount(history.length);
    //     setPlayBooks([...new Set(history.map(item => item.section_name))]);
    //     setEmails([...new Set(history.map(item => item.email).filter(Boolean))]);
    //   } catch (error) {
    //     message.error("Failed to fetch data");
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    getData();
  }, []);

  useEffect(() => {
    const filteredData = allHistory.filter(item => {
      const matchesPlaybook = !searchQuery.playbook || item.section_name.toLowerCase().includes(searchQuery.playbook.toLowerCase());
      const matchesEmail = !searchQuery.email || item.email?.toLowerCase().includes(searchQuery.email.toLowerCase());
      const matchesDate = !searchQuery.date || item.created_at?.split("T")[0] === searchQuery.date;
      return matchesPlaybook && matchesEmail && matchesDate;
    });

    const startIndex = (page - 1) * recordsPerPage;
    setDisplayHistory(filteredData.slice(startIndex, startIndex + recordsPerPage));
    setCount(filteredData.length);
  }, [page, allHistory, searchQuery]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       setIsOpenDropdown({ ...isOpenDropdown, playbook: true });
  //       setHighlightedIndex(-1);
  //     }
  //     if (wrapperRef2.current && !wrapperRef2.current.contains(event.target)) {
  //       setIsOpenDropdown({ ...isOpenDropdown, email: true });
  //       setHighlightedIndex(-1);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => document.removeEventListener("mousedown", handleClickOutside);
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchQuery(prev => ({ ...prev, [name]: value }));
    setPage(1);

    if(name === "playbook"){
    const data= playBooks.filter(suggestion => suggestion.toLowerCase().includes(value.toLowerCase()))
    setPlayBooks(data);
    }else{
    const data=emails.filter(suggestion => suggestion.toLowerCase().includes(value.toLowerCase()));
    setEmails(data);
    }
    setIsOpenDropdown({ ...isOpenDropdown, [name]: true });
    setHighlightedIndex(-1);
  };
  const onPageChange = (page) => {
    setPage(page);
  };

 

  return (
    <>
      {!loading ? (
        <div>
          <TopBar text="Templates History" />
          {allHistory.length > 0 ? (
            <div className="py-5 px-8">
              <div>
                
                <div className="mt-3.5">
                  {localStorage.getItem("email") ==
                    "superadmin@avatare.com" && (
                    <div className="flex justify-start items-center gap-3">
                      <div
                        className="relative max-w-[250px]  w-full"
                        ref={wrapperRef}
                        onClick={() => toggleDropdown("playbook")}
                      >
                        <div className="relative max-w-[250px] w-full bg-[#F7F8FC] border border-gray-300 custom-outline">
                          <input
                            type="text"
                            name="playbook"
                            placeholder="Search playbook"
                            value={searchQuery.playbook}
                            onChange={handleInputChange}
                            className="w-full p-2  bg-[#F7F8FC] max-w-[220px] outline-none"
                          />
                          <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 9L12 15L18 9"
                                stroke="#808080"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          {isOpenDropdown.playbook && playBooks.length > 0 && (
                            <ul className="absolute w-full border border-gray-300 mt-1 max-h-40 overflow-auto bg-white shadow-lg z-10">
                              {playBooks.map((suggestion, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    console.log('Click detected');
                                    handleSelect("playbook", suggestion);
                                }}
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={handleMouseLeave}
                                  className={`p-2 cursor-pointer pointer-events-auto transition-all duration-200 border-b border-gray-400  ${
                                    highlightedIndex === index
                                      ? "bg-gray-100 font-normal"
                                      : " hover:bg-gray-50 hover:pl-3"
                                  }`}
                                >
                                  {suggestion}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div
                        className="relative max-w-[250px]  w-full"
                        ref={wrapperRef2}
                        onClick={() => toggleDropdown("email")}
                      >
                        <div className="relative max-w-[250px] w-full bg-[#F7F8FC] border border-gray-300 custom-outline">
                          <input
                            type="text"
                            name="email"
                            placeholder="Search email"
                            value={searchQuery.email}
                            onChange={handleInputChange}
                            className="w-full p-2  bg-[#F7F8FC] max-w-[220px] outline-none"
                          />
                          <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 9L12 15L18 9"
                                stroke="#808080"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          {isOpenDropdown.email && emails.length > 0 && (
                            <ul className="absolute w-full border border-gray-300 mt-1 max-h-40 overflow-auto bg-white shadow-lg z-10">
                              {emails.map((suggestion, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    console.log('Click detected');
                                    handleSelect("email", suggestion);
                                }}
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={handleMouseLeave}
                                  className={`p-2 cursor-pointer pointer-events-auto transition-all duration-200 border-b border-gray-400  ${
                                    highlightedIndex === index
                                      ? "bg-gray-100 font-normal"
                                      : " hover:bg-gray-50 hover:pl-3"
                                  }`}
                                >
                                  {suggestion}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="relative max-w-[140px]  w-full bg-[#F7F8FC] rounded-[4px]">
                        <input
                          type="date"
                          name="date"
                          value={searchQuery.date}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 bg-[#F7F8FC] outline-none"
                        />
                      </div>
                      <div className="flex ml-auto gap-3">
                        <button
                          onClick={(e) => downloadChats("chats")}
                          disabled={session?.length === 0}
                          className="primary flex items-center !capitalize gap-1 p-3"
                        >
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7 10L12 15L17 10"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 15V3"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          All Chats
                        </button>
                        <button
                          onClick={(e) => downloadChats("outputs")}
                          className="primary  !capitalize flex items-center gap-1 p-3"
                          disabled={session?.length === 0}
                        >
                          {" "}
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7 10L12 15L17 10"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 15V3"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          All Outputs
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="flex gap-5 m-w-[50%] flex-wrap mt-3">
                    <div className="min-w-full  w-fit bg-white rounded-md border cursor-pointer">
                      <div className="p-4 flex max-xl:h-full items-center  justify-start gap-4">
                        <div className="flex items-center gap-4 w-[25%] ml-[3%]">
                          <p className="font-medium text-lg">Playbook Name</p>
                        </div>
                        {localStorage.getItem("email") ==
                        "superadmin@avatare.com" ? (
                          <div className="flex items-center ml-3 gap-2 w-[20%]">
                            <span className="text-lg font-medium">Email</span>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2  w-[20%]"></div>
                        )}

                        <div className="text-lg font-medium w-[5%] ml-[2%] min-w-[100px]">
                          Created on
                        </div>
                        <div>
                          <p className="font-medium text-lg ml-[5%] w-[10%] min-w-[100px]">
                            Status
                          </p>
                        </div>

                        <div className="flex items-center justify-end w-[20%] gap-6 ml-[5%] mr-[3.5%]">
                          <p className="text-lg font-medium">Downloads</p>
                        </div>

                        <div>
                          <p className="text-lg font-medium flex self-end">
                            Actions
                          </p>
                        </div>
                      </div>
                    </div>
                    {displayHistory.map((historyItem, index) => (
                      <MyAvatarCard
                        key={index}
                        session={session}
                        setSession={setSession}
                        title={historyItem.section_name}
                        number={
                          historyItem.section_no.split(".")[1] <= 9
                            ? "0" + historyItem.section_no.split(".")[1]
                            : historyItem.section_no.split(".")[1]
                        }
                        backGround="linear-gradient(180deg, #7FB04F 0%, #497964 100%)"
                        heading={historyItem.heading_name}
                        chatData={historyItem}
                        date={historyItem.created_at}
                        handleDelete={handleDelete}
                      />
                    ))}
                  </div>
                </div>

                <div className="flex items-center justify-center py-4 mb-4">
                  <Pagination
                    current={page}
                    pageSize={recordsPerPage}
                    total={count}
                    showSizeChanger={false}
                    onChange={onPageChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <DataNotAvailable
              text="Looks like you don’t have any Avatare yet"
              subText="Let’s create the first one"
              buttontext="use a template"
              handleClick={handleClick}
            />
          )}
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-screen">
          <Spin size="large" className="custom-spin" />{" "}
        </div>
      )}
    </>
  );
};

export default TemplateHistory;
