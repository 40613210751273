import PlaybookRowOne from "./PlaybookRowOne";
import PlaybookRowTwo from "./PlaybookRowTwo";
const TrackorComponent = ({part,nextPart, nextRow,showFirst,last,headingCircleOne,subHeadingCircleOne,headingCircleTwo,subHeadingCircleTwo}) => {
  const hasFirstHead = part?.some(item => item.lineClass == 'bg-tracker-line-1' || item.lineClass == 'bg-tracker-line-2');
  const hasStyles = nextRow?.some(item => item.lineClass == 'bg-tracker-line-1' || item.lineClass == 'bg-tracker-line-2');
  const hasStylesTwo = nextPart.some(item => item.lineClass == 'bg-tracker-line-1' || item.lineClass == 'bg-tracker-line-2');
  const applyStyleH1 = hasFirstHead ? 'bg-[#6CA6C4]' : 'bg-gray-400';
  const applyStyleH2 = hasFirstHead ? 'text-[#20556E]' : 'text-gray-400';

  return (<div>
      <div className="relative">
        <div className="flex flex-row items-center w-full justify-start">
          <div
            className={`${
              showFirst ===true
                ? "max-w-[120px] max-h-[120px] min-w-[120px]  z-10 rounded-full border-[2px] border-white  "
                : "max-w-[0px] h-full overflow-hidden -mt-[19px]"
            } min-h-[120px] grid grid-cols-1`}
          >
            <h1 className={`col-span-1 flex justify-center items-center ${applyStyleH1} rounded-tr-full rounded-tl-full text-center text-xs p-2 text-white border-b-white border-b-[2px]`}>Start with Problem</h1>
            <h2 className={`bg-[#D9D9D9] rounded-br-full rounded-bl-full col-span-1 flex justify-center items-center p-2 ${applyStyleH2} font-semibold text-xl`}> Part 1</h2>
          </div>
          <PlaybookRowOne blocks={part} hasStylesTwo={hasStylesTwo} headingCircleOne={headingCircleOne} subHeadingCircleOne={subHeadingCircleOne}/></div>
         <PlaybookRowTwo blocks={nextPart} hasStyles={hasStyles} last={last} headingCircleTwo={headingCircleTwo} subHeadingCircleTwo={subHeadingCircleTwo}/>  
      </div> 
      </div>);
      };
export default TrackorComponent;
