import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography,} from "@mui/material";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";

import AudioPlayer from 'react-h5-audio-player'
import "react-h5-audio-player/lib/styles.css";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import ReactTyped from "react-typed";
import { message } from "antd";


const WhisperPlayground = ({totalCredits,audioFile}) => {
  const location = useLocation();
  const userId = localStorage.getItem("UserId")
  const token = localStorage.getItem("token")
  const [whisperData, setWhisperData] = useState(null)
  const [checkcredits,setCheckcredits] = useState()
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/research/whisper/?UserId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        setCheckcredits(dataa?.message )
      } else {
        const dataa = await response.json();
        setCheckcredits(dataa?.message)
        if(dataa?.message == "You dont have sufficient credit points"){
          message.error("you Don't have enough credits."
           )
        }
      }
    } catch (error) {
     
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [audioFile]);

  const uploadFile = async () => {
    fetchData()
    if(checkcredits !== "You dont have sufficient credit points" ){
      const formData = new FormData();
      formData.append("UserId", userId)
      formData.append("audio", audioFile?.file);
      formData.append("duration", audioFile?.duration);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/research/whisper/`, {
          method: "POST",
          body: formData,
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.ok) {
          const dataa = await response.json()
          setWhisperData(dataa)
          fetchData()
          totalCredits()
          console.log("success :", dataa);
        } else {
          console.log("Error :", response);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <>
      <div>
        <p className="font-medium text-lg">
          Whisper Playground
        </p>
        
        <div className="w-full rounded-sm my-5 border border-dashed py-4" 
      style={{ borderColor: "rgba(0, 0, 0, 0.20)" }}

        >
          <AudioPlayer
            src={URL.createObjectURL(audioFile?.file)}
            className="custom-audio-player"
            style={{ border: "none", boxShadow: "none", margin: "0px auto", width: "60%" }}
            showJumpControls={false}
            customAdditionalControls={[]}
          />
        </div>
          
            <button onClick={uploadFile} className="primary px-3 py-2">
              Reveal Transcript
            </button>
          
       {whisperData &&<div  className="flex w-full p-5 border border-dashed rounded-sm my-3"
      style={{ borderColor: "rgba(0, 0, 0, 0.20)" }}

       >
            <ReactTyped
              strings={[whisperData?.message]}
              typeSpeed={50}
              cursorChar="|"
              showCursor={true}
            />
        </div>}


      </div>
    </>
  );
};

export default WhisperPlayground;
