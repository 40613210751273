import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import { Typography, Breadcrumbs, InputLabel, FormControl } from "@mui/material";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";
import MicIcon from '@mui/icons-material/Mic';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import UploadBox from "../../components/Research/UploadBox";
import StartRecording from "../../components/Research/StartRecording";

import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { message } from "antd";
import WhisperPlayground from "../../components/Research/WhisperPlayground";

const WhisperAI = () => {
  const navigate = useNavigate()
  const [audioFile, setAudioFile] = useState({})
  const [buttonText,setButtonText]=useState("Record your audio");
 

  useEffect(()=>{
      if(audioFile?.file){
        message.success("Your file is uploaded Successfully")
        // navigate("/research/whisperplayground",{state:audioFile})
      }
  },[audioFile])

  const handleButtonlcick = ()=>{
    buttonText =="Record Your Audio"? setButtonText("Upload File") : setButtonText("Record Your Audio");
  }

 

  return (
    <>
    <TopBar text="Whisper AI"/>

        <div className="w-full">
          <div className="bg-white p-12 m-12">
          <button className="primary p-3 flex ml-auto mb-4 cursor-pointer" onClick={handleButtonlcick}>{buttonText}</button>
          {buttonText=="Upload File"?<StartRecording  setAudioFile={setAudioFile} />:<UploadBox setAudioFile={setAudioFile}/>}

          {audioFile.file &&<div className="mt-4">
          <WhisperPlayground audioFile={audioFile} />
          </div>}

          </div>

        </div>
       
        
     
    </>
  );
};

export default WhisperAI;