import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const SlideViewer = ({index,heading,title,newChat}) => {
  const navigate=useNavigate();
  return (
    <div className="w-[50%] flex flex-col px-4 py-[4%]">
      <div className='flex flex-col self-end'>
      <p className='font-semibold text-lg'>Conversation Completion Time : <span className='font-normal'>5-15 mins.</span></p>  
      <button onClick={()=>navigate("/sequential-startup/test-template-avatare", { state: {index:index,title:title,heading:heading,newChat:newChat } })} className="primary text-white px-4 py-2 cursor-pointer self-end mt-2 mb-4">start a conversation</button>
      {/* <button onClick={()=>navigate("/sequential-startup/customize-bot")} disabled className="secondary text-black px-4 py-2  ml-4  cursor-pointer ">Customize Template</button> */}
      </div><div className="mt-12 flex justify-center items-center">
        <div  className="mx-[10%] border-2 border-dashed flex justify-center items-center  h-[10%] min-h-[350px] min-w-[350px] w-[10%] border-gray-400 rounded-full mb-4" >
        <div className='w-full flex  justify-center'> <img src="/template-icon.svg" /></div>
        </div></div> </div>
  );
}
export default SlideViewer;
