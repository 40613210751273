import React from "react";
import AvatareModelTraining from "../../components/Administration/AvatareModelTraining/AvatareModelTraining";
import { useParams, useLocation } from "react-router-dom";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { Select } from "antd";

const SelectModel = () => {
  const [model, setModel] = React.useState("");
  const [listModels, setListModels] = React.useState([]);
  const [botName, setBotName] = React.useState("");
  const [botDesc, setBotDesc] = React.useState("");
  const [emoji, setEmoji] = React.useState("");

  let { Id } = useParams();
  const state = useLocation();
  const { DataIds } = state;

  React.useEffect(() => {
    let mounted = true;
    getData().then((items) => {
      console.log(items.message);
      const models = Array.isArray(items?.message)
        ? items?.message.map((model) => ({
            label: model.key,
            value: model.value,
          }))
        : [{ label: "No Model", value: null }];
      setListModels(models);
    });

    let mounted2 = true;
    getBotData().then((items) => {
      console.log(items);
      setBotName(items.message.BotName);
      setBotDesc(items.message.BotDescription);
      setEmoji(items.message.emojiS3Link);
    });
    return () => (mounted2 = false);
  }, []);

  const getData = async () => {
    return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/modelslist/`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }).then((data) => data.json());
  };

  const getBotData = async () => {
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/bot/getbotdetails/?BotId=` + Id,
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    ).then((data) => data.json());
  };
  return (
    <>
      <TopBar text={"Train By AI Model"} backArrow={true} />
      <div className="bg-white m-8 p-4 rounded-sm">
        <h2 className="text-xl font-medium text-black">Select Model</h2>
        <p className=" text-black text-opacity-70 font-normal mb-2">
          Select the AI Model from below list to train it.
        </p>
        <div className="mt-4">
          <Select
            showSearch
            style={{
              height: "50px",
            }}
            className="mt-6 w-1/3  focus:outline-none focus:border-[#000]"
            placeholder={"Select the AI Model from below list to train it."}
            optionFilterProp="label"
            onChange={(value) => setModel(value)}
            onSearch={(value) => setModel(value)}
            options={listModels}
          />
        </div>
        {console.log(model)}
        {model ? (
          <div className="mt-4">
            <AvatareModelTraining
              BotId={Id}
              name={botName}
              model={model}
              description={botDesc}
              DataIds={DataIds}
              button_message={"Train"}
              emoji={emoji}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SelectModel;
