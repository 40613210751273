import React
// { useEffect }
 from "react";
import Container from "@mui/material/Container";

// import { styled, alpha } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
import QuestionBoxInScore from "../QuestionBoxInScore.js/QuestionBoxInScore";
import BetterAnswerModal from "../BetterAnswerModal/BetterAnswerModal";
import { Pagination } from "antd";

const QuestionsAndAnswers = ({
  open,
  setOpen,
  questions,
  answers,
  dataObjects,
  Id,
  IdName,
  rerender,
  setRerender,
  questionId,
  setQuestionId,
  test
}) => {

  const question= "";
  const [questionsToBeShown, setQuestionsToBeShown] = React.useState([]);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(0);

  React.useEffect(() => {
    setQuestionsToBeShown(dataObjects);
    console.log("setRerender: ", setRerender);
  }, [dataObjects]);



  const goToPage = (e, page) => {
    setPageNumber(page - 1);
    console.log(page);
  };

  const getResults = async (question) => {
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/bot/getchats/?BotId=${Id}&Question=${question}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
    ).then((data) => data.json());
  };

  const getQAs = async (question) => {
    if(IdName=="DataId"){
      return fetch(
        `${process.env.REACT_APP_API_ROOT}/api/data/searchQAs/?DataId=${Id}&Question=${question}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
      ).then((data) => data.json());

    }
    else{
      return fetch(
        `${process.env.REACT_APP_API_ROOT}/api/data/searchQAs/?SourceId=${Id}&Question=${question}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
      ).then((data) => data.json());
    }
  };

  const searchQuestions = async (e) => {
    var question = document.getElementById("search").value;
    var currentScreen = window.location.href.split("/")[4];
    setSearchQuery(question);
    if (currentScreen == "QuestionsAndAnswers") {
      getQAs(question).then((result) => {
        console.log(result);
        if (typeof result.message == "string") {
          setQuestionsToBeShown([]);
        } else {
          setQuestionsToBeShown(result.message);
        }
      });
    } else {
      getResults(question).then((result) => {
        console.log(result);
        setQuestionsToBeShown(result.message);
      });
    }
  };
  return (
    <div>
      {/* <Search
        sx={{
          border: "1px solid #ABABAB",
          color: "#295598",
          height: "49px",
          width: "45vw!important",
          mt: "29px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          type="button"
          onClick={searchQuestions}
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <StyledInputBase
          id="search"
          placeholder="Search questions here."
          inputProps={{ "aria-label": "search" }}
        />
      </Search> */}
      <div
      
      >
        <div className="bg-white px-6 py-4 m-6 h-full w-full">
        <div className="flex justify-between items-center mt-5">
          <p className="text-black text-opacity-25"> 
          Here is the list of    question in file.
          </p>
          {/* <button className="primary px-3 py-2">Add More Question</button> */}
          </div>
        {Array.isArray(questionsToBeShown) ? questionsToBeShown
          .slice(pageNumber * 10, pageNumber * 10 + 10)
          .map((dataObject) => (
            <div className="mt-6">
            <QuestionBoxInScore
              index={dataObject?.Id ? dataObject?.Id : dataObject?.ChatId}
              open={open}
              botId={dataObject?.BotId}
              setOpen={setOpen}
              question={dataObject?.Question}
              answer={dataObject?.Answer}
              rank={dataObject?.Rating}
              setQuestionId={setQuestionId}
              rerender={rerender}
              setRerender={setRerender}
              
            />
            </div>
          )):<></>
          }
          </div>
        {/* {(() => {
          const rows = [];
          for (let i = 0; i <= 6; i++) {
            rows.push(<QuestionBoxInScore index={i+1} open={open} setOpen={setOpen} question={questions[i]} answer={answers[i]} setQuestion={setQuestion} dataObjects={dataObjects}/>);
          }
          return rows;
        })()} */}
      </div>
      <div
        sx={{
          m: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Pagination
          count={parseInt(questionsToBeShown.length / 10) + 1}
          size="large"
          onChange={goToPage}
        />
      </div>
      {questionId != 1 ? 
      <BetterAnswerModal
        Id={questionId}
        BotId={Id}
        open={open}
        setOpen={setOpen}
        question={question}
      />
      : 
      <></>}
    </div>
  );
};

export default QuestionsAndAnswers;
