import React, { useRef } from "react";

export const FileUploader = ({ text, desc, accept, icon, supportedFiles, uploadedFile, handleFileUpload }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => fileInputRef.current?.click();

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files.length) {
      handleFileUpload({ target: { files: event.dataTransfer.files } });
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center gap-1.5">
      <h1 className="font-medium text-lg">{text}</h1>
      <p>{desc}</p>
      <div
        style={{ borderColor: "rgba(0, 0, 0, 0.20)" }}
        className="mt-[3%] w-full flex flex-col justify-center items-center py-8 gap-2 border border-dashed cursor-pointer border-spacing-60"
        aria-label="attach"
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <input
          accept={accept}
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        {!uploadedFile ? (
          <>
            <img src={icon} alt="Upload Icon" />
            <h1 className="text-sm text-gray-700">
              Drag & Drop files here or
              <span className="text-black mx-1 font-medium text-sm underline underline-offset-2">Browse</span>
              here
            </h1>
            <h1 className="text-xs text-gray-700">Supported files: {supportedFiles}</h1>
          </>
        ) : (
          <h1 className="text-lg font-medium text-gray-700">{uploadedFile.name}</h1>
        )}
      </div>
    </div>
  );
};
