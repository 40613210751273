import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Popup from '../../common/PopUp';
import PopUpData from '../../common/PopUpData';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: "30px 10px",
  fontFamily: "Gordita",
  borderRadius: "18px"
};


const AvatarCreditModel = ({open,handleCloseModel,modelData,modelStaus,setOpen,handleClick,loading}) => {
  return (
    <div>
    {/* <Modal
      open={open}
      onClose={handleCloseModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} >
        {modelStaus == 200 ?
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
          <Typography id="modal-modal-title" sx={{ fontSize: "24px", fontWeight: "600", color: "black", textAlign: "center" }}>
          {modelData.message}
          </Typography>
          <Typography id="modal-modal-title" sx={{ fontSize: "20px", fontWeight: "500", color: "black", textAlign: "center" }}>
         Are you sure you want to continue ?
          </Typography>
          <Box sx={{ml:"auto",mt:"20px",mb:"5px"}}>
            <Button variant='contained' sx={{ mx:"5px"}} onClick={p}>
                Start Training
            </Button>
            <Button variant='outlined' sx={{ mx:"5px"}} onClick={()=>setOpen(false)}>
                Close
            </Button>
          </Box>
        </Box>
         : 
         <>
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
          <Typography id="modal-modal-title" sx={{ fontSize: "24px", fontWeight: "600", textAlign: "center",color:"red" }}>
            {modelData.message}
          </Typography>
        </Box>
        </>
        }  
      </Box>
    </Modal> */}
    <PopUpData
      isOpen={open}
      onClose={handleCloseModel}
      handleClone={handleClick}
      loading={loading}
      text={modelData.message}
      fromTrainig={true}
      desc={ "Are you sure you want to continue ?"}
      buttonTextOne={"Start Training"}


     />
  </div>
  )
}

export default AvatarCreditModel