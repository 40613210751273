import React, { useState } from "react";
import { TopBar } from "../../components/CommonComponent/Topbar";
import FileProgress from "../../components/common/FileProgress";
import { AvatareName } from "../../components/Administration/CreateAvatareFromScratch/AvatareName";
import { FileUploader } from "../../components/common/FileUploader";
import { Description } from "../../components/Administration/CreateAvatareFromScratch/Description";
import { message } from "antd";
import axios from "axios";
import PopUp from "../../components/common/PopUp";

export const CreateAvatareFromScratch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const data = ["Name", "Image/Profile", "Description"];
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [active, setActive] = useState([]);
  const [avatareName, setAvatareName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const handleNext = () => {
    if (currentIndex == -1 && avatareName.trim() === "") {
      message.error("please enter avatare name");
      return;
    }
    setCurrentIndex(currentIndex + 1);
    setActive([...active, currentIndex + 1]);
  };

  const handleBack = () => {
    setCurrentIndex(currentIndex - 1);
    active.pop();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file.type === "image/png" || file.type === "image/svg" ||file.type === "image/jpeg") {
      const MIN_FILE_SIZE = 1;
      const MAX_FILE_SIZE = 300;
      const fileSizeKiloBytes = event.target.files[0].size / 1024;

      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        message.error("File size is less than minimum limit");
        return;
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        message.error("File size is greater than 300KB");
        return;
      } else {
        const fileUploaded = event.target.files[0];
        setUploadedFile(fileUploaded);
        setFile(URL.createObjectURL(fileUploaded));
      }
    }
    else{
      message.error("Please upload supported files.")
    }
  };

  const handleCreateAvatare = async () => {
    try {
      const body = new URLSearchParams({
        BotName: avatareName,
        BotDescription: description,
        UserId: localStorage.getItem("UserId"),
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_ROOT}/api/bot/createbot/`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      console.log("response:", response);

      if (response.status === 400) {
        message.error("Bot with this name already exist");
        return;
      }

      const data = response.data;
      if (uploadedFile) {
        const formData = new FormData();
        formData.append("BotId", data.message.BotId);
        formData.append("BotEmoji", uploadedFile);

        try {
          const uploadResponse = await axios.put(
            `${process.env.REACT_APP_API_ROOT}/api/bot/createbot/`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          );

          if (uploadResponse.status !== 200 && uploadResponse.status !== 201) {
            message.error("Error in emoji upload!");
          } else {
            setIsModalOpen(true);
          }
        } catch (uploadError) {
          console.log(uploadError);
          message.error("Error uploading emoji");
        }
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log("Error:", error);
      message.error("Bot with this name already exist");
    }
  };

  return (
    <div>
      <TopBar text="Create New Avatare" />

      <div className="bg-white m-14 px-[12%]">
        <FileProgress
          pl="30px"
          fileNames={data}
          currentIndex={currentIndex}
          active={active}
          chat={false}
          initialValue={1}
        />
        <div className="pl-8 py-[7%]">
          {currentIndex < 0 ? (
            <AvatareName
              avatareName={avatareName}
              setAvatareName={setAvatareName}
            />
          ) : currentIndex == 0 ? (
            <FileUploader
              accept="image/png, image/svg, image/jpeg"
              icon={"/file-upload-icon.svg"}
              supportedFiles={"png, jpeg, svg"}
              text="Add Image to your Avatare (optional)" 
              desc="Image enhances overall look and give it more meaning."
              uploadedFile={uploadedFile}
              handleFileUpload={handleFileUpload}
            />
          ) : currentIndex == 1 && (
            <Description
              description={description}
              setDescription={setDescription}
            />

          )}
          <div className="flex w-full justify-center items-center gap-4 mt-7">
            {currentIndex > -1 && (
              <button
                onClick={() => handleBack()}
                className="secondary px-8 py-2 flex justify-center items-center gap-2"
              >
                <img src="/back-icon.svg" />
                Back
              </button>
            )}
            {currentIndex < 1 ? (
              <button
                onClick={() => handleNext()}
                className="px-4 py-2 flex justify-center items-center gap-2 text-white primary"
              >
                Next Step
                <img src="/next-icon.svg" />
              </button>
            ) : (
              <button
                onClick={handleCreateAvatare}
                className="px-4 py-2  primary text-white"
              >
                Create Avatare
              </button>
            )}
          </div>
        </div>
      </div>
      <PopUp
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        text={"New Avatar-E Created"}
        desc={"New Avatare created successfully. Train it now to use further"}
        buttonTextOne={"Train it Now"}
        buttonTextTwo={"I’ll do it later"}
      />
    </div>
  );
};
