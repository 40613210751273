import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
import AvataresContainer from "../../components/Classroom/AvataresContainer/AvataresContainer";
import { TopBar } from "../../components/CommonComponent/Topbar";
import DataNotAvailable from "../../components/common/DataNotAvailable";
import { useNavigate } from "react-router-dom";

const TrainBySubject = () => {
  const navigate= useNavigate();
  const [avatares, setAvatares] = React.useState([])
    const [searchQuery, setSearchQuery] = React.useState("");
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };
    const filterItems = (items) => {
      if (!searchQuery || searchQuery.trim() === '') {
        return items;
      }
      return items.filter((item) => {
        const botName = item?.BotName;
        if (!botName) return false;
        return botName.toLowerCase().includes(searchQuery.toLowerCase());
      });
    };

  const filteredAvatares = filterItems(avatares);

  const getData = async () => {
    console.log("Right before hitting")
      let userId = localStorage.getItem('UserId')
      return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=` + userId,{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then(
          data => data.json())
  }
  React.useEffect(() => {
    let mounted = true;
    getData()
      .then(items => {
        console.log("Items: ", items);
        if(typeof items.message != Object) {
          setAvatares(items.message);
        }
      })
    return () => mounted = false;
  }, [])
  return (
    <>
    
         
          <TopBar text={"Train By Subject"} />
          <div className="py-2 px-8 bg-white m-10 rounded-sm">
             
             {filterItems.length > 0 ? <div>
             <div>
             <div className="flex w-full justify-between items-center mt-3 pr-2">
                     <h2 className="text-xl font-medium text-black">
                       Select Bot
                     </h2>
                     <div>
                     <div className="relative w-[350px] bg-[#F7F8FC] rounded-[4px]">
                    <div className="absolute inset-y-0 top-0 left-0 pl-3 flex items-center pointer-events-none">
                      <img src="/search.svg" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search template here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="w-full p-2 pl-10 border-[0.5px] bg-[#F7F8FC] custom-outline"
                    />
                  </div>
                     </div>
              </div>
                   <p className=" text-black text-opacity-70 font-normal mb-2">
                   Select the bot from below list to train it.
                   </p>
                   </div>
               <div className="mt-3.5 mb-20">
          <AvataresContainer avatares={filteredAvatares}   />
               </div>
             </div>:
              <DataNotAvailable text="Looks like you don’t have any Avatare yet" subText="Let’s create the first one" handleClick={(e)=>navigate("/admin/createAvatar")} buttontext={"Let's create"}  />}
             
           </div>
        
    </>
  );
};

export default TrainBySubject;