import React, { useRef, useState } from "react";
import { message } from "antd";
import { FileUploader } from "../common/FileUploader";

const UploadBox = ({ setAudioFile }) => {
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [key, setKey] = useState(0);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    const MIN_FILE_SIZE = 1;
    const MAX_FILE_SIZE = 25000;
    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      message.error("File size is less than minimum limit");
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      message.error("File size is greater than maximum limit");
      return;
    }
    if (!["audio/mpeg", "audio/mp3", "audio/wav", "audio/x-m4a"].includes(selectedFile.type)) {
      message.error("This file type is not supported");
      setKey((prevKey) => prevKey + 1);
      return;
    }

    setUploadedFile(selectedFile);

    try {
      const audio = new Audio();
      audio.src = URL.createObjectURL(selectedFile);
      audio.onloadedmetadata = () => {
        setAudioFile({ file: selectedFile, duration: audio.duration });
        message.success("File uploaded successfully");
      };
    } catch (error) {
      console.error("Error calculating audio duration: ", error);
      message.error("Failed to calculate audio duration");
    }
  };

  return (
    <FileUploader
      accept="audio/mpeg, audio/mp3, audio/wav, audio/x-m4a"
      icon="/file-upload-icon.svg"
      supportedFiles="mpeg, mp3, wav, x-m4a"
      text="File Upload"
      desc="Speech to text tool - Upload your audio file below"
      uploadedFile={uploadedFile}
      handleFileUpload={handleFileChange}
    />
  );
};

export default UploadBox;